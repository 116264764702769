import { PropsWithChildren, ReactElement } from "react";
import styles from "./HeroMini.module.scss";
import heromini_bg from "../../../assets/media/hero_mini_bg.svg";

interface IHeroMiniProps {
    title: string;
}

function HeroMini(props: PropsWithChildren<IHeroMiniProps>): ReactElement {
    const { title } = props;

    return (
        <section className={styles.heroMini}>
            <div className={styles.heroMini_content}>
                <h1 className={styles.heroMini_title}>{title}</h1>
            </div>
            <img src={heromini_bg} alt="Alerton Engineering & Commissioning Certification" />
        </section>
    );
}

export default HeroMini;
