import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { IRoute, routes } from "../routes";
import Router from "../Router";
import { PrivateRoute } from "../PrivateRoute";

export default function RouteSwitch(): ReactElement {
    return (
        <Router>
            <Routes>
                {routes.map((route: IRoute) =>
                    <Route
                        key={route.url}
                        path={`/${route.url}`}
                        element={route.isPublic ? (route.element) : (<PrivateRoute>{route.element}</PrivateRoute>)}
                    />
                )}
                <Route path="/" element={
                    <Navigate to="/home" />
                } />
                <Route path="*" element={
                    <Navigate to="/404" />
                } />
            </Routes>
        </Router>
    );
}