import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import { currencyDollarUS, formatDate, formatDateAndTime, getStartTime, timeConvert } from "../../../helpers";
import ATSList from "../../../components/Shared/ATSList";
import styles from "./PaymentSuccess.module.scss";
import alerton_summary_logo from "../../../assets/media/alerton_summary.svg";
import detail_bg_top from "../../../assets/media/checkout_bg02.svg";
import detail_bg_bottom from "../../../assets/media/checkout_bg01.svg";

function PaymentSuccess(props: { state: any; enrolledSucces: any; selectedInstance: any; dealer: any | null; courseInfo: any; }) {
    const { enrolledSucces, selectedInstance, dealer, courseInfo } = props;

    return (
        <section className={styles.layout}>
            {/* Do a component success for this, if it's necessary */}
            <div className={styles.card}>
                <i className="icon-check"></i>
                <h2 className={styles.card_title}>thanks for your order!</h2>
                {enrolledSucces.pending ? (
                    <p>
                        You will need to confirm your payment using the link sent to{" "}
                        {dealer.email ? <strong>{dealer.email}</strong> : "your email"}, before we can proceed and submit your enrollment request
                        for approval. Once the payment is approved, we will send you an email when your request has been reviewed and accepted.
                    </p>
                ) : (
                    <p>
                        Your payment was successful, and your enrollment request has been submitted for approval. We will send you an email when
                        your request has been reviewed and accepted
                    </p>
                )}
            </div>
            <article className={`${styles.summary} print`}>
                <div className={styles.summary_heading}>
                    <img src={alerton_summary_logo} alt="Alerton Training Summary" />
                    <p className={styles.summary_heading_id}>Id #{enrolledSucces.id}</p>
                    <ATSButton iconClass="icon-print" cta="" type={ATSTypeButton.DEFAULT} action={() => window.print()} />
                </div>

                <div className={styles.summary_body}>
                    <div className={styles.summary_body_info}>
                        <div className="left">
                            <p>
                                <strong>Course - </strong>
                                <span>{courseInfo.name}</span>
                            </p>
                            <p>
                                <strong>Location - </strong>
                                <span>{courseInfo.locationName}</span>
                            </p>
                            <p>
                                <strong>Date and time - </strong>
                            </p>
                            {selectedInstance.dates.map((date, i) => (
                                <p key={`courseDates_${i}`}>
                                    <span>
                                        {formatDate(date.date)}{" "}
                                        {timeConvert(getStartTime(date.startTime, date.startTimeMinute))}
                                    </span>
                                </p>
                            ))}
                            <p>
                                <strong>Dealer - </strong>
                                <span>{dealer?.name}</span>
                            </p>
                            <p>
                                <strong>Seats - </strong>
                                <span>{enrolledSucces.students.length}</span>
                            </p>
                        </div>
                        <div className="right">
                            <div className={styles.total_box}>
                                <p>
                                    <strong>Billing Amount</strong>
                                </p>
                                <p className={styles.space_between}>
                                    {" "}
                                    Course <span>{currencyDollarUS.format(enrolledSucces.amount)}</span>
                                </p>
                                <p className={styles.space_between}>
                                    {" "}
                                    Tax <span>{currencyDollarUS.format(enrolledSucces.tax)}</span>
                                </p>

                                <h4 className={`${styles.total} ${styles.space_between}`}>
                                    <strong>TOTAL</strong> <strong>{currencyDollarUS.format(enrolledSucces.amount + enrolledSucces.tax)}</strong>
                                </h4>
                            </div>
                        </div>
                    </div>

                    <div className={styles.summary_body_list}>
                        <div className={styles.summary_body_list_content}>
                            <ATSList dataSet={enrolledSucces.students} entity={"students"} onClickHandler={() => { }} ignoredColumns={["id"]} />
                        </div>
                    </div>

                    <p className={styles.summary_body_date}>{formatDateAndTime(enrolledSucces.date)}</p>
                </div>

                <ATSButton cta="Go to courses" type={ATSTypeButton.POSITIVE} url="/courses" />
            </article>

            <img className={styles.layout_bg_top} src={detail_bg_top} alt="Background pattern" />
            <img className={styles.layout_bg_bottom} src={detail_bg_bottom} alt="Background pattern" />
        </section>
    );
}

export default PaymentSuccess;
