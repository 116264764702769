import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { GetDealerByTokenRequest } from "../interfaces/serviceRequests/dealer/IDealerByTokenRequest";
import { GetDealerByTokenResponse } from "../interfaces/serviceResponses/dealers/IDealerByTokenReponse";

class DealerService extends ApiBaseService {
    public static async getByToken(token: string): Promise<IResponse<GetDealerByTokenResponse>> {
        const response = await this.get<GetDealerByTokenResponse>(`dealer/token/${token}`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            return response;
        }
    }

}

export default DealerService;