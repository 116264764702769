import styles from './StepIndicator.module.scss';

function StepsIndicator({ totalSteps, actualStep }) {
    return (
        <div className={styles.steps_box}>
            <ul className={`${styles.steps} ${styles['step_' + actualStep]}`}>
                {totalSteps.map((step, i) => (
                    <li key={`step-${i}`} title={step} className={(i === actualStep) ? styles.active : ''}>
                        <i className="icon-check"></i>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default StepsIndicator;