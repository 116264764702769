import Home from "../Pages/Home";
import Courses from "../Pages/Courses";
import ContactUs from "../Pages/Contact";
import NotFound from "../Pages/NotFound";
import PrivacyPolicy from "../Pages/Privacy";
import TermsConditions from "../Pages/Terms";
import Checkout from "../Pages/Checkout";
import FAQ from "../Pages/FAQ";
import TrainingSchedule from "../Pages/TrainingSchedule";

export interface IRoute {
    url: string;
    label?: string;
    isPublic: boolean;
    element: JSX.Element;
}

export const routes = Object.freeze<IRoute[]>([
    {
        url: "home",
        label: "Home",
        isPublic: true,
        element: <Home />,
    },
    {
        url: "courses",
        label: "Courses",
        isPublic: true,
        element: <Courses />,
    },
    {
        url: "contact",
        label: "Contact Us",
        isPublic: true,
        element: <ContactUs />,
    },
    {
        url: "checkout",
        label: "Checkout",
        isPublic: true,
        element: <Checkout />,
    },
    {
        url: "faq",
        label: "FAQs",
        isPublic: true,
        element: <FAQ />,
    },
    {
        url: "privacy-policy",
        label: "Privacy Policy",
        isPublic: true,
        element: <PrivacyPolicy />,
    },
    {
        url: "terms-conditions",
        label: "Terms and Conditions",
        isPublic: true,
        element: <TermsConditions />,
    },
    {
        url: "404",
        label: "Not Found",
        isPublic: true,
        element: <NotFound />,
    },
    {
        url: "schedule",
        label: "Schedule",
        isPublic: true,
        element: <TrainingSchedule />,
    },
]);
