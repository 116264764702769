import ATSButton, { IATSButtons } from "../ATSButton";
import styles from "./ATSAlertBox.module.scss";

interface IATSAlertBoxProps {
    type: ATSAlertBoxType;
    content: string;
    title?: string;
    buttons?: Array<IATSButtons>;
}

export enum ATSAlertBoxType {
    DEFAULT = "cool",
    ERROR = "danger",
    SUCCESS = "success",
    WARNING = "warning",
}

const iconType = {
    cool: "info",
    danger: "close",
    success: "verified",
    warning: "warning",
};

/**
 * Create ATS AlertBox component with buttons.
 * @param type: ATSAlertBoxType;
 * @param content: string;
 * @param title?: string;
 * @param buttons?: Array<IATSButtons>;
 */
function ATSAlertBox(props: IATSAlertBoxProps) {
    const { type, content, title } = props;
    return (
        <section className={styles.alertBox_container}>
            <div className={`${styles.alertBox} ${type}`}>
            <article className={`${styles.alertBox_content}`}>
                <i className={`icon-${iconType[type]} ${styles.alertBox_icon}`}></i>
                    <h4 className={styles.alertBox_title}>{title}</h4>
                    <p className={styles.alertBox_text}>{content}</p>
                    {props.buttons && props.buttons.map(button => <ATSButton {...{ ...button }} key={button.cta} />)}
            </article>
            </div>
        </section>
    );
}

export default ATSAlertBox;
