import { NavLink } from "react-router-dom";
import styles from "./Footer.module.scss";
import logo from "../../../assets/media/AlertonTraining_Logo_White.svg";
import { useEffect, useState } from "react";

interface ILayoutFooterProps {
    title: string;
}

function Footer(props: ILayoutFooterProps) {
    const { title } = props;
    const [showCookies, setShowCookies] = useState<Boolean>(false);
    const [showCookiesConsent, setShowCookiesConsent] = useState<Boolean>(false);

    useEffect(() => {
        const consent = sessionStorage.getItem("cookieConsent");
        if (consent === "true") {
            setShowCookiesConsent(false);
            setShowCookies(false);
        } else {
            setShowCookiesConsent(true);
            setTimeout(() => {
                setShowCookies(true);
            }, 400);
        }
    }, []);

    function setCookiesConsent() {
        setShowCookies(false);
        setTimeout(() => {
            setShowCookiesConsent(false);
            sessionStorage.setItem("cookieConsent", "true");
        }, 400);
    }

    function scrollToTop() {
        document.querySelector("body")?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }

    return (
        <footer className={styles.footer}>
            <div className={styles.footer_top}>
                <div className={styles.footer_top_content}>
                    <div className={styles.footer_top_area}>
                        <NavLink to="/home">
                            <img src={logo} alt="Alerton Training Logo" />
                        </NavLink>
                    </div>
                    <div className={styles.footer_top_area}>
                        <h6 className={styles.footer_title}>Courses</h6>
                        <NavLink key="courses" className={styles.ats_cta} to={`/courses#compass`}>
                            <span>Compass Operator Training</span>
                        </NavLink>
                        <NavLink key="courses2" className={styles.ats_cta} to={`/courses#alerton-engineering`}>
                            <span>Alerton Engineering & Commissioning Certification</span>
                        </NavLink>
                        <NavLink key="courses3" className={styles.ats_cta} to={`/courses#alerton-programming`}>
                            <span>Alerton Programming Certification Training</span>
                        </NavLink>
                    </div>
                    <div className={styles.footer_top_area}>
                        <h6 className={styles.footer_title}>About us</h6>
                        <NavLink key="contact" className={styles.ats_cta} to={`/contact`}>
                            <span>Contact</span>
                        </NavLink>
                        <NavLink key="faq" className={styles.ats_cta} to={`/faq`}>
                            <span>Frequently Asked Questions</span>
                        </NavLink>
                        <NavLink key="privacy" className={styles.ats_cta} to={`/privacy-policy`}>
                            <span>Privacy Policy</span>
                        </NavLink>
                        <NavLink key="terms" className={styles.ats_cta} to={`/terms-conditions`}>
                            <span>Terms of Services</span>
                        </NavLink>
                    </div>
                </div>
            </div>
            <div className={styles.footer_bottom}>
                <p>Designed by the Alerton Training Crew | Copyright © 2022 Bowles Properties llc</p>
            </div>

            <button className={styles.goto_top} onClick={scrollToTop}>
                <i className="icon-straight"></i>
            </button>
            {showCookiesConsent && (
                <div className={`${styles.cookies} ${showCookies ? styles.active : ""}`}>
                    <div className={styles.cookies_content}>
                        <h4 className={styles.cookies_title}>Cookies & Privacy</h4>
                        <p>
                            We use cookies to understand how you use our site and to improve your experience. This includes personalizing content
                            and advertising. By continuing to use this website or closing this banner, you accept our use of first and
                            third-party cookies.
                        </p>
                        <div className={styles.cookies_buttons}>
                            <button className={styles.cookies_btn} onClick={setCookiesConsent}>
                                Ok, understood
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </footer>
    );
}

export default Footer;
