import Layout from "../../components/Layout"
import HeroMini from "../../components/Shared/HeroMini";

function PrivacyPolicy() {
    return (
        <Layout title={"Privacy Policy"}>
            <HeroMini title={"Privacy Policy"}></HeroMini>
            <article className="onlyText">
                <h2 className="title">How we collect information?</h2>
                <p>
                    Information that you provide us: whenever you complete and submit our contact forms you agree to give Alerton Training personal information such as full name, email, the company you work for, and any other information you choose to add in the messages. 
                </p>
                <p>
                    Automatically collected information: we reserve the right to collect information through internal tools and third-party services (Google Analytics). For more information on the use of Google Analytics and its policies you can visit: google.com/analytics/policies.
                </p>
                <p>
                    Alerton Training’s website uses cookies. When you visit Alerton Training’s website, we may recollect the following information:
                </p>

                <ul>
                    <li>
                        Information about your visits to this website such as page views, session duration, referral source, and navigation paths you take.
                    </li>
                    <li>
                        Information about the device and browser you are using, your location, and IP address.
                    </li>
                    <li>
                        Information about your age, gender, and personal preferences.
                    </li>
                </ul>

                <h2 className="title">What are cookies?</h2>
                <p>
                    Cookies are text files that may be sent to and are stored in your web browser when you access a website. Some of them are always needed for the website to function correctly and others are optional. You can access your browser configuration to manually disable them. Note that in doing so the Alerton Training’s website might not function correctly.
                </p>

                <h2 className="title">How do we use the collected information?</h2>

                <p>We use the collected information to:</p>
                <ul>
                    <li>Provide general statistics regarding use of the Alerton Training website. 
                    </li>
                    <li>
                        Maintain and improve the quality of the services we provide and our communications.
                    </li>
                    <li>
                        Contact via email those who have agreed to message us. 
                    </li>
                    <li>
                        Deliver relevant advertisements based on the use of the website.
                    </li>
                </ul>

                <h2 className="title">How is it stored/secured?</h2>

                <p>
                    We use the collected information to:
                </p>

                <ul>
                    <li>
                        Provide general statistics regarding use of the Alerton Training website. 
                    </li>
                    <li>
                        Maintain and improve the quality of the services we provide and our communications.
                    </li>
                    <li>
                        Contact via email those who have agreed to message us. 
                    </li>
                    <li>
                        Deliver relevant advertisements based on the use of the website.
                    </li>
                </ul>

                <h2 className="title">How to opt out giving said information?</h2>

                <p>
                    You can click here to opt out of enabling cookies that collect said information. Remember that in doing so the Alerton Training’s website might not function correctly. 
                </p>

                <h2 className="title">How to contact us regarding questions and inquires?</h2>

                <p>
                    If you have any questions or concerns regarding our privacy policy you can email: <a href="mailto:privacy@alertontraining.com">privacy@alertontraining.com</a>

                </p>

                <h2 className="title">Privacy Policy update</h2>

                <p>
                    We may update our privacy policy from time, to time so we encourage you to visit our privacy policy page frequently.

                </p>
            </article>

        </Layout>
    );
  }
  
  export default PrivacyPolicy