export interface IAddPaymentDealerRequest {
    courseInstanceId: number | null;
    token: string | null;

    slots: number;
    students: AddPaymentStudent[];

    method: PaymentMethods;
    paymentData: AddPaymentCreditCard | AddPaymentACH;
}

export interface AddPaymentCreditCard {
    cardNumber: string;
    cvc: string;
    expirationMonth: number | null;
    expirationYear: number | null;
    name: string;
    country: string;
    postalCode: string;
}

export interface AddPaymentACH {
    accountNumber: string;
    routingNumber: string;
    holderName: string;
    accountHolderType: string;
    ipAddress: string;
    userAgent: string;
}

export interface AddPaymentManual {
    title: string;
    comment: string;
}

export interface AddPaymentStudent {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
}

export enum PaymentMethods {
    CreditCard = 1,
    ACH = 2,
    Manual = 3,
}