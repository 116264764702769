import styles from "./ATSCoursesTile.module.scss";
import { NavLink } from "react-router-dom";
import { getStartTime, timeConvert } from "../../../helpers";

export default function ATSCoursesTiles({ data, color, image, title, text, value }) {
    const newData = data?.courseList[0];
    const date = new Date(newData?.date);
    const formattedDate = new Date(date.getTime() - date.getTimezoneOffset() * -60000);
    const [courseMonth, courseDay] = formattedDate.toLocaleDateString("en-us", { month: "short", day: "numeric" }).split(" ");
    const dayCourse = data?.courseList.length;

    return (
        <>
            <div className={`${styles.tileContainer} color ${color}`}>
                <div className={styles.imageContainer}>
                    <img src={image} alt={newData?.courseName ?? title} />
                </div>
                {data ? (
                    <>
                        <div className={styles.textContainer}>
                            <div className={styles.title}>
                                <h3>{newData.courseName}</h3>
                                <h4>{newData.location}</h4>
                            </div>
                            <div className={styles.date}>
                                <div className={styles.courseDay}>
                                    <span className={styles.month}>{courseMonth}</span>
                                    <span className={styles.day}>{courseDay}</span>
                                    <span className={styles.hour}>
                                        {timeConvert(getStartTime(newData.startTime, newData.startTimeMinute))}
                                    </span>{" "}
                                </div>
                                <span className={styles.middleLine}></span>
                                <div className={styles.dayLeft}> {dayCourse} day course </div>
                            </div>
                        </div>
                        <NavLink className={styles.button} to={"/schedule"} state={{ activeFilter: value }}>
                            See More
                        </NavLink>
                    </>
                ) : (
                    <div className={styles.defaultTile}>
                        <h3>{title}</h3>
                        <p>{text}</p>
                    </div>
                )}
            </div>
        </>
    );
}
