import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { IAddContactFormRequest } from "../interfaces/serviceRequests/contactForm/IAddContactFormRequest";
import { IAddContactFormResponse } from "../interfaces/serviceResponses/contactForm/IAddContactFormResponse";

class ContactFormService extends ApiBaseService {
    public static async submitForm(request: IAddContactFormRequest): Promise<IResponse<IAddContactFormResponse>> {
        const response = await this.post<IAddContactFormResponse>(`contact-form`, request);
        return response;
    }
}

export default ContactFormService;