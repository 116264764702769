import Layout from "../../components/Layout"
import styles from "./notfound.module.scss";
import detail from "../../assets/media/404_detail.svg";
import cross from "../../assets/media/404_cross.svg";

function NotFound() {
  return (
    <Layout title={"Page not found"}>
      <section className={styles.layout}>
        <div className={styles.layout_aside}>
            <img src={detail} />
        </div>
        <div className={styles.layout_content}>
          <div className={styles.layout_box}>
            <h1 className={styles.layout_title}>404</h1>
            <p>We are sorry, the page you requested could not be found. Please go back to the homepage</p>

            <a href="/" className={styles.layout_btn}>Go to Home</a>
          </div>
          
          <img src={cross} />
        </div>
      </section>
    </Layout>
  )
}

export default NotFound