import React, { useState } from "react";
import { currencyDollarUS, formatDate, getStartTime, timeConvert } from "../../../helpers";
import { AddPaymentStudent } from "../../../services/interfaces/serviceRequests/payment/IAddPaymentDealerRequest";
import { GetCourseInstanceDataResponse } from "../../../services/interfaces/serviceResponses/course/ICoursesResponse";
import StudentsStep from "../StudentsStep";
import styles from "./Summary.module.scss";

interface ISummaryProps {
    course: string;
    location: string;
    seats: number;
    dealer: any | null;
    instance: GetCourseInstanceDataResponse | null;
    students: AddPaymentStudent[];
}

function Summary(props: ISummaryProps) {
    const { course, location, seats, dealer, instance, students } = props;
    const [expand, setExpand] = useState<boolean>(false);
    return (
        <div className={`${styles.resume}`}>
            <article className={styles.summary}>
                <h2 className={styles.summary_title}>Summary</h2>

                <h4 className={styles.summary_subtitle}>Course</h4>
                <p>{course}</p>
                <div className={styles.summary_columns}>
                    <div>
                        <h4 className={styles.summary_subtitle}>Location</h4>
                        <p>{location}</p>
                    </div>
                    <div>
                        <h4 className={styles.summary_subtitle}>Seats</h4>
                        <p>{students.length}</p>
                    </div>
                </div>
                {instance && (
                    <>
                        <h4 className={styles.summary_subtitle}>Date and Time</h4>
                        {instance.dates.map((date, index) => (
                            <p key={`date-${index}`}>
                                {formatDate(date.date as any)}{" "}
                                {timeConvert(getStartTime(date.startTime, date.startTimeMinute))}
                            </p>
                        ))}
                    </>
                )}
                {dealer && (
                    <>
                        <h4 className={styles.summary_subtitle}>Dealer</h4>
                        <p>{dealer.name}</p>
                    </>
                )}
                {students && students.find(e => e.firstName !== "" || e.lastName !== "") && (
                    <>
                        <h4 className={styles.summary_subtitle}>Students</h4>
                        {students.map(
                            (student, i) =>
                                (student.firstName || student.lastName) && (
                                    <p key={`student_${i}`}>{`${i + 1} - ${student.firstName + " " + student.lastName}`}</p>
                                )
                        )}
                    </>
                )}
            </article>

            {instance?.price && dealer && (
                <article className={`${expand ? styles.show : ""} ${styles.totalprice}`}>
                    <div className={styles.totalprice_top}>
                        <h2 className={styles.totalprice_text}>
                            Total Price : <span>{currencyDollarUS.format(instance.price * students.length)}</span>
                        </h2>
                        <button className={styles.totalprice_btn} onClick={() => setExpand(!expand)}>
                            <span>Details</span>
                            <i className="icon-expand_less"></i>
                        </button>
                    </div>
                    <ul className={styles.totalprice_expand}>
                        <li>
                            <p>
                                <span>Course:</span>
                                <span>
                                    {students.length} x {currencyDollarUS.format(instance.price)}
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <span>Tax:</span>
                                <span>$ - </span>
                            </p>
                        </li>
                    </ul>
                </article>
            )}

            <article className={styles.cancelPolicy}>
                <div className={styles.cancelPolicy_heading}>
                    <i className="icon-info"></i>
                    <h2 className={styles.cancelPolicy_heading_title}>Cancellation policy</h2>
                </div>
                <ul className={styles.cancelPolicy_content}>
                    <li>
                        <p>
                            <strong>15+ </strong>business days before the course:
                        </p>
                        <p>
                            <strong>Fee waived</strong>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>14 - 10 </strong>business days before the course:
                        </p>
                        <p>
                            <strong>50% </strong>of tuition is forfeited
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>09 - 00 </strong>business days before the course:
                        </p>
                        <p>
                            <strong>100% </strong>of tuition is forfeited
                        </p>
                    </li>
                </ul>
            </article>
            <article className={styles.summaryWarning}>
                <div className={styles.summaryWarning_heading}>
                    <i className="icon-warning"></i>
                    <h2 className={styles.summaryWarning_heading_title}>
                        Once submitted, your enrollment request will be reviewed by our team. All enrollments will be pending until approved by
                        Alerton training.
                    </h2>
                </div>
                <p className={styles.summaryWarning_content}>We will send you an email when your enrollment request has been accepted</p>
            </article>
        </div>
    );
}

export default Summary;
