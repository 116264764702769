import { useEffect } from "react";
import ATSAccountNumberAchInput from "../../../../components/form/ATSAccountNumberAchInput";
import ATSRoutingNumberAchInput from "../../../../components/form/ATSRoutingNumberAchInput";
import ATSSelect, { SelectOption } from "../../../../components/form/ATSSelect";
import ATSTextInput from "../../../../components/form/ATSTextInput";
import { copyJSON } from "../../../../helpers";
import getIp from "../../../../utils/getIP";


interface AchMethodProps {
    state: any;
    errors?: any;
    handleInputChange: (name: string, value: string | number, _indexed?: string, _index?: number) => void;
    handleErrorsChange?: (name: string, value: string[], _indexed?: string, _index?: number) => void;
}
function AchMethod(props: AchMethodProps) {
    const { handleInputChange, state, errors, handleErrorsChange } = props;
    const holderType: Array<SelectOption> = [
        {
            label: 'Company',
            value: 'company'
        },
        {
            label: 'Individual',
            value: 'individual'
        }];

    const manageAchData = (name, value) => {
        let mutateState = copyJSON(state.paymentData);
        mutateState = {
            ...mutateState,
            [name]: value
        };
        handleInputChange('paymentData', mutateState);
    };

    const manageErrorAch = (name, value) => {
        if (!handleErrorsChange) return;
        let mutateState = errors?.ach ? copyJSON(errors.ach) : {};
        mutateState = {
            ...mutateState,
            [name]: value
        };
        handleErrorsChange('ach', mutateState);
    };


    useEffect(() => {
        getDeviceData();
    }, []);

    const getDeviceData = async () => {
        const ip = await getIp();
        const userAgent = navigator.userAgent;
        let mutateAchState = copyJSON(state.paymentData);
        mutateAchState = {
            ...mutateAchState,
            ipAddress: ip.ip,
            userAgent: userAgent
        };
        handleInputChange("paymentData", mutateAchState);
    };

    return (
        <fieldset className="form_fieldset maxWidth">
            <ATSAccountNumberAchInput
                name={"accountNumber"}
                label={"Account Number"}
                value={state.paymentData.accountNumber}
                handleChange={manageAchData}
                error={errors && errors.ach && errors.ach.accountNumber ? errors.ach.accountNumber : []}
                setErrorMessage={manageErrorAch}
                maxLength={12}
                required
            />
            <ATSRoutingNumberAchInput
                name={"routingNumber"}
                label={"Routing Number"}
                value={state.paymentData.routingNumber}
                handleChange={manageAchData}
                error={errors && errors.ach && errors.ach.routingNumber ? errors.ach.routingNumber : []}
                setErrorMessage={manageErrorAch}
                maxLength={9}
                required
            />
            <ATSTextInput
                name={"holderName"}
                label={"Holder Name"}
                value={state.paymentData.holderName}
                handleChange={manageAchData}
                error={errors && errors.ach && errors.ach.holderName ? errors.ach.holderName : []}
                setErrorMessage={manageErrorAch}
                required
            />
            <ATSSelect
                options={holderType}
                name={"accountHolderType"}
                label={"Holder Type"}
                placeholder={"Select Holder Type"}
                handleChange={manageAchData}
                error={errors && errors.ach && errors.ach.accountHolderType ? errors.ach.accountHolderType : []}
                setErrorMessage={manageErrorAch}
                required
            />
        </fieldset>
    );
}

export default AchMethod;