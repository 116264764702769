import ATSDateTile from "./ATSDateTile";
import styles from "./ATSCalendarGrid.module.scss";
import { useNavigate } from "react-router-dom";
import { ICourses } from "../../../services/interfaces/serviceResponses/course/ICoursesResponse";

interface ICalendarGrid {
    data: ICourses;
    icon: string;
    color: string;
    anchor: string;
    title: string;
    text: string;
}

export default function ATSCalendarGrid({ data, icon, color, anchor, title, text }: ICalendarGrid) {
    const navigate = useNavigate();
    return (
        <div className={`${styles.calendar_wrapper} color ${color}`}>
            <div className={styles.calendar_header}>
                <div className={styles.title_container}>
                    <img src={icon} alt={"Course icon"}></img>
                    <h2>{title}</h2>
                </div>
                {data.courses.length > 0 && (
                    <button onClick={() => navigate(`/courses#${anchor}`)} className={`${styles.desktop_btn} ${styles.enroll_btn}`}>
                        Enroll
                    </button>
                )}
            </div>
            {data?.courses.length > 0 ? (
                <>
                    {data.courses.map((row, i) => {
                        return (
                            <div className={`${styles.calendar_row}`} key={i}>
                                <div className={styles.calendar_container}>
                                    <div className={styles.place_container}>
                                        <span>{row.courseList[0].location}</span>
                                        <span className={styles.dayDuration}>{row.courseList.length} day course</span>
                                    </div>
                                    <div className={styles.date_tile_container}>
                                        <ATSDateTile
                                            date={row.courseList[0].date}
                                            hour={row.courseList[0].startTime}
                                            minutes={row.courseList[0].startTimeMinute}
                                            title="First day"></ATSDateTile>
                                        <span className={styles.dash_divider}></span>
                                        <ATSDateTile
                                            date={row.courseList[row.courseList.length - 1].date}
                                            hour={row.courseList[row.courseList.length - 1].startTime}
                                            minutes={row.courseList[row.courseList.length - 1].startTimeMinute}
                                            title="Last day"></ATSDateTile>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <button onClick={() => navigate(`/courses#${anchor}`)} className={`${styles.mobile_btn} ${styles.enroll_btn}`}>
                        Enroll
                    </button>{" "}
                </>
            ) : (
                <div className={styles.defaultTitle}>
                    <p> {text}</p>
                </div>
            )}
        </div>
    );
}
