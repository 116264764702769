import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ATSTypeButton, IATSButtons } from "../../../components/Shared/ATSButton";
import ATSAlertBox, { ATSAlertBoxType } from "../../../components/Shared/ATSAlertBox";
import detail_bg_top from "../../../assets/media/error_detail_top.svg";
import detail_bg_bottom from "../../../assets/media/error_detail_bottom.svg";

import styles from "./PaymentFail.module.scss";

interface CheckoutFailProps {
    error?: string;
    buttons?: Array<IATSButtons>;
}

function CheckoutFail(props: CheckoutFailProps) {
    const { error, buttons } = props;
    //const location = useLocation();
    const navigate = useNavigate();
    //const locationError = location.state as any;
    const [errorMsg, setErrorMsg] = useState<string | null>();

    useEffect(() => {
        if (error) setErrorMsg(error);
        else setErrorMsg("There was an error and the payment could not be processed.");
    }, []);

    const buttonGoBack: Array<IATSButtons> = [
        {
            cta: "Go back",
            type: ATSTypeButton.DANGER,
            action: () => navigate(-1),
        },
    ];

    return (
        <section className={styles.layout}>
            <ATSAlertBox
                type={ATSAlertBoxType.ERROR}
                content={errorMsg ? errorMsg : ""}
                title="WE ARE SO SORRY!"
                buttons={buttons ?? buttonGoBack}
            />
            <img className={styles.layout_bg_top} src={detail_bg_top} alt="Background pattern" />
            <img className={styles.layout_bg_bottom} src={detail_bg_bottom} alt="Background pattern" />
        </section>
    );
}

export default CheckoutFail;
