import Layout from "../../components/Layout";
import styles from "./contact.module.scss";
import contact_detail from "../../assets/media/contact_detail.svg";
import contact_detail_bg from "../../assets/media/contact_detail_bg.svg";
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import ContactFormService from "../../services/entitiesServices/ContactFormService";
import { IAddContactFormRequest } from "../../services/interfaces/serviceRequests/contactForm/IAddContactFormRequest";
import { NavLink } from "react-router-dom";
import ATSModal, { ATSBodyTextTypeModal, ATSButtonAlignmentModal, ATSTypeModal } from "../../components/Shared/ATSModal";
import { ATSTypeButton } from "../../components/Shared/ATSButton";

function Contact() {
    const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
    const contactFormInit: IAddContactFormRequest = {
        email: "",
        name: "",
        subject: "",
        description: "",
        type: "",
        terms: false,
        recaptchaToken: "",
    };

    const recaptchaRef = React.createRef<any>();
    const [contactForm, setContactForm] = useState<IAddContactFormRequest>(contactFormInit);
    const [recaptchaValue, setRecaptchaValue] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState<boolean>(false);

    function disableSubmit() {
        return (
            !contactForm.email ||
            !contactForm.description ||
            !contactForm.name ||
            !contactForm.type ||
            !contactForm.subject ||
            !contactForm.terms ||
            recaptchaValue === ""
        );
    }

    const handleInputChange = (e): void => {
        if (e.target.name === "terms") {
            setContactForm(prev => ({
                ...prev,
                [e.target.name]: !prev.terms,
            }));
        } else {
            setContactForm(prev => ({
                ...prev,
                [e.target.name]: e.target.value,
            }));
        }
    };

    async function submitForm(e) {
        e.preventDefault();
        const recaptchaValue = recaptchaRef.current.getValue();

        const formData = { ...contactForm, recaptchaToken: recaptchaValue };
        setLoading(true);
        const response = await ContactFormService.submitForm(formData);
        if (response.success) {
            setContactForm(contactFormInit);
            setSuccessModal(!successModal);
        } else {
            setErrorModal(!errorModal);
        }
        setLoading(false);
    }

    return (
        <Layout title={"Contact"}>
            <section className={styles.layout}>
                <div className={styles.layout_info}>
                    <h1 className={styles.layout_info_title}>Hi!</h1>
                    <p>
                        <strong>Questions about Alerton training paths, our courses, or how you can become registered for a class?</strong>
                    </p>

                    <p>Please submit your inquiries using this form and we will get back to you as soon as possible.</p>

                    <p>
                        While you are waiting for a reply, please visit our{" "}
                        <NavLink key="faq" className={styles.ats_cta} to={`/faq`}>
                            Frequently Asked Questions
                        </NavLink>{" "}
                        page.
                    </p>

                    <img src={contact_detail} alt="Contact Banner" />
                </div>
                <div className={styles.layout_form}>
                    <form onSubmit={submitForm}>
                        <div className={styles.item}>
                            <input
                                id="email"
                                type="email"
                                placeholder="  "
                                maxLength={100}
                                value={contactForm.email}
                                name="email"
                                onChange={e => handleInputChange(e)}
                            />
                            <label htmlFor="email">Your email address</label>
                        </div>

                        <div className={styles.item}>
                            <input
                                id="name"
                                type="text"
                                placeholder=" "
                                maxLength={100}
                                value={contactForm.name}
                                name="name"
                                onChange={e => handleInputChange(e)}
                            />
                            <label htmlFor="name">Full name</label>
                        </div>

                        <div className={styles.item}>
                            <select id="type" name="type" defaultValue={"default"} onChange={e => handleInputChange(e)}>
                                <option value="default" hidden disabled>
                                    Who are you?
                                </option>
                                <option value="dealer">Dealer Representative</option>
                                <option value="individual">Individual Customer</option>
                            </select>
                        </div>

                        <div className={styles.item}>
                            <input
                                id="subject"
                                type="text"
                                placeholder=" "
                                maxLength={100}
                                value={contactForm.subject}
                                name="subject"
                                onChange={e => handleInputChange(e)}
                            />
                            <label htmlFor="subject">Subject</label>
                        </div>

                        <div className={styles.item}>
                            <textarea
                                name="description"
                                id="description"
                                placeholder=" "
                                value={contactForm.description}
                                onChange={e => handleInputChange(e)}></textarea>
                            <label htmlFor="description">Description</label>
                        </div>

                        <div className={styles.item_check}>
                            <label htmlFor="terms">
                                <input
                                    type="checkbox"
                                    name="terms"
                                    id="terms"
                                    checked={contactForm.terms}
                                    onChange={e => handleInputChange(e)}
                                />
                                <span>Terms of Service</span>

                                <p>
                                    I have read and agree to the Terms of Service located <NavLink to={`/terms-conditions`}>here.</NavLink>
                                </p>
                            </label>
                        </div>

                        <div className={styles.item}>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey={recaptchaKey}
                                onChange={() => {
                                    setRecaptchaValue(recaptchaRef.current.getValue());
                                }}
                            />
                        </div>

                        <button type="submit" className={`${styles.item_submit} ${loading ? styles.loading : ""}`} disabled={disableSubmit()}>
                            {`${!loading ? "Submit" : "Submitting..."}`}
                        </button>
                    </form>
                </div>

                <img src={contact_detail_bg} alt="Design pattern background" />
            </section>
            {successModal && (
                <ATSModal
                    title="Contact request"
                    icon="icon-check_circle_fill success"
                    bodyText={["Your inquiries have been sent successfully!", "We will try to reply as soon as possible."]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setSuccessModal(!successModal)}
                    buttons={[
                        {
                            cta: "Ok",
                            type: ATSTypeButton.OUTPOSITIVE,
                            action: () => {
                                setSuccessModal(!successModal);
                            },
                        },
                    ]}
                />
            )}
            {errorModal && (
                <ATSModal
                    title="Contact request"
                    icon="icon-error danger"
                    bodyText={["Sorry, something went wrong.", "If the problem persist call an administrator."]}
                    type={ATSTypeModal.DEFAULT}
                    buttonsAlignment={ATSButtonAlignmentModal.CENTERED}
                    handleClose={() => setErrorModal(!errorModal)}
                    buttons={[
                        {
                            cta: "Ok",
                            type: ATSTypeButton.OUTPOSITIVE,
                            action: () => {
                                setErrorModal(!errorModal);
                            },
                        },
                    ]}
                />
            )}
        </Layout>
    );
}

export default Contact;
