import React, { PropsWithChildren, ReactElement, useRef, useState } from "react";
import { CardNumFormatter } from "./cardsInfo";
import styles from './ATSCardInput.module.scss';
import { CardType } from "./Interfaces";
import ATSErrorMsg from "../ATSErrorMsg";

interface ICardInputProps {
    name: string;
    label: string;
    required?: boolean;
    errors?: Array<string>;
    setErrorMessage?: (name: string, value: string[]) => void;
    handleChange(name: string, value: string): void;
}


/**
 * Create custom card number input 
 * @param name string ; 
 * @param label string ;
 * @param handleChange ( )=> void -> setState function that recieves a name and value;
 * @param required boolean ; 
 * @param errors Array<string>;
 * @param setErrorMessage ( )=> void -> setState function that recieves a name and value;
 * The input validate all credit card types and return a logo for VISA, AMEX, DISCOVER or DEFAULT also check if you use an invalid number and 
 * format your number like your credit card look. All these validations and formater are made from a cardInfo file.
 */
const ATSCardInput = (props: PropsWithChildren<ICardInputProps>): ReactElement => {
    const { name, label, handleChange, errors, setErrorMessage } = props;
    const [creditCardType, setCreditCardType] = useState<number>(CardType.UNKNOWN);
    //Use ref use memo.
    const [formatter] = useState(new CardNumFormatter());

    const matchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setErrorMessage) setErrorMessage(name, []);

        let formattedValue = formatter.format(e.target.value);
        let maxLenght = formatter.maxLength;
        setCreditCardType(formatter.active != null ? formatter.active : CardType.UNKNOWN);

        e.target.value = formattedValue;
        e.target.maxLength = maxLenght;
    };

    const blurHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let validationError = formatter.validate(e.target.value);
        // -- Validate value
        if (setErrorMessage) setErrorMessage(name, validationError);
        //set card number 
        handleChange(name, e.target.value);
    };

    const matchCreditCardIcon = (creditCardType: number) => {
        switch (creditCardType) {
            case CardType.AMEX:
                return <i className="icon-amex"></i>;
            case CardType.MASTERCARD:
                return <i className="icon-mastercard"></i>;
            case CardType.VISA:
                return <i className="icon-visa"></i>;
            case CardType.DISCOVER:
                return <i className="icon-discover"></i>;
            default:
                return <i className="icon-generic_card"></i>;
        }
    };

    return (
        <div className={`form_item ${styles.cardNumberWrapper} ${(errors && errors?.length > 0) ? "danger" : ''}`}>
            <label>{label}</label>
            <input type="text" onChange={e => matchHandler(e)} className={styles.cardNumber} onBlur={e => blurHandler(e)} name={name} />
            {matchCreditCardIcon(creditCardType)}
            {(errors && errors.length > 0) && <ATSErrorMsg errors={errors} />}
        </div>
    );
};

export default ATSCardInput;
