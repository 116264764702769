import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { ICourseDropdownResponse } from "../interfaces/serviceResponses/checkout/ICourseDropdownResponse";
import { ICourseInstanceDropdownResponse } from "../interfaces/serviceResponses/checkout/ICourseInstanceDropdownResponse";
import { ILocationDropdownResponse } from "../interfaces/serviceResponses/checkout/ILocationDropdownResponse";

class CheckoutService extends ApiBaseService {
    public static async getCourses(): Promise<IResponse<ICourseDropdownResponse>> {
        const response = await this.get<ICourseDropdownResponse>(`checkout/dropdowncourses`, {});
        return response;
    }

    public static async getLocations(courseId: number): Promise<IResponse<ILocationDropdownResponse>> {
        const response = await this.post<ILocationDropdownResponse>(`checkout/dropdownlocations`, { courseId });
        return response;
    }

    public static async getCourseInstances(courseId: number, locationId: number): Promise<IResponse<ICourseInstanceDropdownResponse>> {
        const response = await this.post<ICourseInstanceDropdownResponse>(`checkout/dropdowncourseinstances`, { courseId, locationId });
        return response;
    }
}

export default CheckoutService;