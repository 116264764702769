import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import styles from "./trainingSchedule.module.scss";
import ATSCalendarGrid from "../../components/Shared/ATSCalendarGrid";
import ATSCustomSelect from "../../components/Shared/ATSCustomSelect";
import HeroMini from "../../components/Shared/HeroMini";
import course_detail_logo from "../../assets/media/course_detail_footer.svg";
import ATSSpinner from "../../components/Shared/ATSSpinner";
import { tileColorDict, logoDict, anchorDict } from "../../helpers/coursesDictionaries";
import { ICourses } from "../../services/interfaces/serviceResponses/course/ICoursesResponse";
import { useLocation } from "react-router-dom";
import CourseService from "../../services/entitiesServices/CoursesService";
interface IFilterOption {
    label: string;
    value: string;
    default?: boolean;
}

export default function TrainingSchedule() {
    const defaultText = "There are no courses available at this moment.";
    const defaultOption = { label: "All Course Type", value: "all_course_type" };
    const [courses, setCourses] = useState<ICourses[]>([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation() as any;
    const activeFilter = location.state?.activeFilter;
    const [filter, setFilter] = useState(activeFilter ? activeFilter : defaultOption.value);
    const [dropdownOptions, setDropdownOptions] = useState<IFilterOption[]>([]);

    function handleFilterChange(option) {
        setFilter(option.value);
        window.history.replaceState({}, "");
    }

    async function getCourses() {
        const coursesData = await CourseService.getCoursesWithDate();
        if (coursesData && coursesData.success) {
            setCourses(coursesData.response);
            const sortedCourses = [...coursesData.response].sort((a, b) => a.priority - b.priority);
            setDropdownOptions([
                defaultOption,
                ...sortedCourses.map(course => {
                    return { label: course.name, value: course.value, default: activeFilter === course.value };
                }),
            ]);
            setLoading(false);
        }
    }

    useEffect(() => {
        getCourses();
    }, []);

    const filteredCourses = !filter || filter === defaultOption.value ? courses : courses.filter(course => course.value === filter);
    return (
        <>
            <Layout title={"Training Schedule"}>
                <HeroMini title={"Training Schedule"} />
                {!loading ? (
                    <div className={styles.page_wrapper}>
                        <div className={styles.page_container}>
                            <div className={styles.select_wrapper}>
                                <div className={styles.select_container}>
                                    {dropdownOptions.length > 0 && (
                                        <ATSCustomSelect options={dropdownOptions} handleChange={handleFilterChange} />
                                    )}
                                </div>
                            </div>
                            {filteredCourses.length > 0 &&
                                filteredCourses.map(course => (
                                    <ATSCalendarGrid
                                        key={course.value}
                                        data={course}
                                        icon={logoDict[course.value]}
                                        color={tileColorDict[course.value]}
                                        anchor={anchorDict[course.value]}
                                        title={course.name}
                                        text={defaultText}
                                    />
                                ))}{" "}
                        </div>
                        <div className={styles.footer_detail_container}>
                            <img className={styles.course_detail_footer} src={course_detail_logo} alt="Course Detail Logo" />
                        </div>
                    </div>
                ) : (
                    <div className={styles.loadingContainer}>
                        <div className={styles.ats_layoutSpinnerContainer}>
                            <ATSSpinner title="Loading..." />
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}
