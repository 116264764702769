import { PropsWithChildren, ReactElement } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Hero.module.scss";
import logo from "../../../assets/media/AlertonTraining_Logo_White.svg";
import background from "../../../assets/media/hero_bg.svg";
import background_mobile from "../../../assets/media/hero_bg_mobile.svg";

interface IHeroProps {
    title: string;
    description: string;
    cta: {label: string, url: string};
}

function Hero(props: PropsWithChildren<IHeroProps>): ReactElement {
    const { title, description, cta } = props;

    return (
        <section className={styles.hero}>
            <div className={styles.hero_container}>
                <div className={styles.hero_content}>
                    <img src={logo} alt="" />

                    <div className={styles.hero_content_text}>
                        <p>
                            <span className={styles.hero_content_text_high}>Welcome to the official Alerton Training website.</span> We provide in-person and virtual instructor-led training for building automation companies and professionals. 
                        </p>
                        <p>
                            Our certified training courses help students learn how to operate their Alerton systems with greater efficiency, confidence, and skill. Click below to get started!
                        </p>
                    </div>

                    <NavLink key={cta.url} className={styles.hero_btn} to={`${cta.url}`}>
                        <span>{cta.label}</span>
                    </NavLink>
                </div>
            </div>
            <img className={styles.hero_bg} src={background} alt="" />
            <img className={styles.hero_bg_mobile} src={background_mobile} alt="" />
        </section>
    );
}

export default Hero;
