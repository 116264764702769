import { useEffect, useRef } from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import styles from "./ATSTextInput.module.scss";

interface IATSTextInputProps {
    name: string;
    value: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    resetValues?: boolean;
    error?: Array<string>;
    setErrorMessage?: (name: string, value: Array<string>) => void;
    handleChange: (name: string, value: string) => void;
    maxLengthProps?: number;
}

/**
 * Create custom input text.
 * @param name string ;
 * @param value string;
 * @param label string ; -> optional
 * @param placeholder string ;
 * @param required boolean; -> Activate error on click outside withot value
 * @param resetValues boolean-> Reset ATS input
 * @param error Array<string>;
 * @param setErrorMessage ( )=> void -> setState function that recieves a name and value;
 * @param handleChange ( )=> void -> setState function that receive a name and value;
 * @param maxLengthProps number -> optional
 */

const ATSTextInput = (props: IATSTextInputProps) => {
    const { name, value, label, placeholder, resetValues, required, handleChange, error, setErrorMessage, maxLengthProps } = props;

    const refContainer = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (resetValues && refContainer.current) {
            refContainer.current.value = "";
        }
    }, [resetValues]);

    function handleInputChange(value: string) {
        setErrorMessage && setErrorMessage(name, []);
        handleChange(name, value);
    }

    function handleBlurInput(value: string) {
        if (value === "" && required) {
            setErrorMessage && setErrorMessage(name, [`This field is mandatory and you did not provide a value.`]);
        }
    }

    return (
        <div className={`form_item ${styles.wrapper} ${error && error?.length > 0 ? "danger" : ""}`}>
            {label && <label>{label}</label>}
            <input
                className={styles.wrapper_input}
                type="input"
                ref={refContainer}
                placeholder={placeholder}
                onChange={e => handleInputChange(e.target.value)}
                onBlur={e => handleBlurInput(e.target.value)}
                value={value}
                maxLength={maxLengthProps ?? 100}
            />
            {error && <ATSErrorMsg errors={error} />}
        </div>
    );
};

export default ATSTextInput;
