import Layout from "../../components/Layout";
import HeroMini from "../../components/Shared/HeroMini";

function FAQ() {
    return (
        <Layout title={"FAQ"}>
            <HeroMini title={"Frequently Asked Questions"}></HeroMini>
            <article className="onlyText">
                <h2 className="title">Welcome!</h2>
                <p>
                    If you are an Alerton dealer and would like to register as a user or add a student to our ongoing waitlist, please send us a
                    message through the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        AlertonTraining.com Contact
                    </a>{" "}
                    page. For all other questions, see below.
                </p>
                <h2 className="title">Signing Up for Alerton, Instructor-Led Training Courses:</h2>
                <h3 className="subtitle">
                    I’m an Alerton dealer. How do I get started signing up students for Alerton, instructor-led training courses?
                </h3>
                <p>
                    As an Alerton dealer, you are authorized to sign up your employees and customers for instructor-led courses through the
                    AlertonTraining.com website.
                </p>
                <p>
                    The first thing you will need to do is send us a message through the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        AlertonTraining.com Contact
                    </a>{" "}
                    page requesting to get set up as a dealer within the new system. Once you provide your dealer details and contact
                    information, Alerton Training will issue you a unique token that you will use to show proof of authorization during the
                    enrollment process.
                </p>
                <p>Once you have your token, you can begin signing up students for any available classes on the AlertonTraining.com website.</p>
                <h3 className="subtitle"> What is a token?</h3>
                <p>
                    A token is a unique passcode issued to Alerton dealers which allows them to sign up their employees and customers for
                    classes. A single token is supplied to a verified contact at each Alerton Dealer. Each Alerton Dealer is responsible for
                    managing who in their organization has access to this token. Tokens may not be shared outside of the designated Alerton
                    dealer or with non-dealer persons, such as customers.
                </p>
                <h3 className="subtitle">How do I get a dealer token?</h3>
                <p>
                    To receive a dealer token, submit a request to be set up in the AlertonTraining.com system by submitting a request through
                    the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        AlertonTraining.com Contact
                    </a>{" "}
                    page. Only authorized Alerton dealers are eligible to receive a token.
                </p>
                <h3 className="subtitle">
                    I’m an end-user, not an Alerton dealer or representative. How do I sign up for Alerton, instructor- led training courses?
                </h3>
                <p>
                    The Alerton Training team is excited to hear that you are interested in taking the next steps to become an expert Alerton
                    system operator!
                </p>
                <p>
                    To sign up for an AlertonTraining.com class, you will need{" "}
                    <a
                        href="https://buildings.honeywell.com/us/en/brands/our-brands/alerton/locate-a-dealer-page"
                        target="_blank"
                        rel="noreferrer">
                        your local Alerton dealer
                    </a>{" "}
                    your local Alerton dealer to sponsor you for enrollment. Your local Alerton dealer will be able to help you decide which
                    class best suits your goals and then they will submit the necessary enrollment information on your behalf. They can also
                    provide detailed information about pricing and overall training expectations.
                </p>
                <p>
                    To find your local Alerton dealer, visit{" "}
                    <a
                        href="https://buildings.honeywell.com/us/en/brands/our-brands/alerton/locate-a-dealer-page"
                        target="_blank"
                        rel="noreferrer">
                        Locate an Authorized Alerton Dealer.
                    </a>{" "}
                </p>
                <h3 className="subtitle">How do I sign up for Alerton, self-paced online training courses?</h3>
                <p>
                    Online, self-paced training courses are offered through My Honeywell Buildings University. Examples of some popular classes
                    include HVAC Fundamentals, Networking Fundamentals, and Windows Fundamentals. To enroll in self-paced, online training
                    contact{" "}
                    <a
                        href="https://buildings.honeywell.com/us/en/brands/our-brands/alerton/locate-a-dealer-page"
                        target="_blank"
                        rel="noreferrer">
                        your local Alerton dealer
                    </a>{" "}
                    .
                </p>
                <h2 className="title">Enrollments, Payments, Schedules, and Locations:</h2>
                <h3 className="subtitle">How do I find my local Alerton dealer?</h3>
                <p>
                    You can find your local Alerton dealer by visiting the{" "}
                    <a
                        href="https://buildings.honeywell.com/us/en/brands/our-brands/alerton/locate-a-dealer-page"
                        target="_blank"
                        rel="noreferrer">
                        Locate an Authorized Alerton Dealer.
                    </a>{" "}
                    page on the Honeywell website. Only Alerton authorized dealers can enroll students in our training courses.
                </p>
                <h3 className="subtitle">How do I make a payment for training?</h3>
                <p>
                    If you are an Alerton dealer signing up a student for training, you can submit payment at the end of the enrollment request
                    and checkout process. AlertonTraining.com accepts credit card and ACH payment methods.
                </p>
                <h3 className="subtitle">What happens after an enrollment request is submitted?</h3>
                <p>
                    After an Alerton dealer submits an enrollment request for one or more students, the AlertonTraining.com team will review the
                    request and ensure that all students have met the necessary prerequisites.
                </p>
                <p>
                    Once this is confirmed, an enrollment approval email will be sent to the dealer and an AlertonTraining.com representative
                    will begin distributing the enrollment and class details directly to the student via the email address provided during
                    registration. The dealer contact will also be included on all relevant student communications.
                </p>
                <h3 className="subtitle">What can I expect after enrolling a student in an Alerton, instructor-led training class?</h3>
                <p>
                    Once an enrollment request has been successfully submitted and approved by the AlertonTraining.com team, an email
                    confirmation will be sent to the enrolled student and the dealer sponsor contact. The email will include details for your
                    upcoming training, including date and start times, location and travel details, and any other information pertinent to the
                    training session.
                </p>
                <p>
                    A reminder will be sent out one week before class starts and we encourage students to always reach out to the
                    AlertonTraining.com team with any questions they have leading up to the day-of.
                </p>
                <h3 className="subtitle">Where can I find the class schedule?</h3>
                <p>
                    You can view the current Alerton training schedule on the Schedule page at{" "}
                    <a href="https://alertontraining.com/schedule" target="_blank" rel="noreferrer">
                        AlertonTraining.com/schedule
                    </a>
                    . Current class offerings are also shown on each of the individual course pages and are distributed monthly via the
                    AlertonTraining.com newsletter. To sign up for the newsletter or to inquire about additional class dates, send us a message
                    through the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        AlertonTraining.com Contact
                    </a>{" "}
                    page.
                </p>
                <h3 className="subtitle">Where do you offer classes?</h3>
                <p>
                    We currently offer in-person classes at our training facility located in the{" "}
                    <a href="https://www.atsinc.org/support/training-services/" target="_blank" rel="noreferrer">
                        greater Seattle area
                    </a>{" "}
                    and virtually via Teams.
                </p>
                <h3 className="subtitle">Do you offer classes at customer locations?</h3>
                <p>
                    At this time, our availability to provide training at customer locations is very limited. If you are unable to attend a class
                    at our Seattle area location, then we recommend attending one of our virtual training sessions. However, we are always happy
                    to discuss all available training options with you. Please send us a message through the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        AlertonTraining.com Contact
                    </a>{" "}
                    page.
                </p>
                <h2 className="title">Alerton, Instructor-Led Course Details:</h2>
                <h3 className="subtitle">
                    What is the difference between an in-person, instructor-led class and a virtual, instructor-led class?
                </h3>
                <p>
                    In-person, instructor-led classes take place at one of our designated training facilities during a specified date and time
                    and will require the student to be physically present for the training. An instructor is on site to deliver the training
                    in-person.
                </p>
                <p>
                    Virtual, instructor-led classes take place remotely, online via special remote-connection and video-conferencing software.
                    They take place during a specified date and time, but can be attended from anywhere in the world (as long as that place has a
                    strong wifi connection). An instructor will deliver the training in real-time over a virtual platform.
                </p>
                <h3 className="subtitle">What do I need in order to participate in virtual, instructor-led training?</h3>
                <p>
                    To participate in virtual, instructor-led training, you need a computer with a strong Wifi connection, a working camera and
                    microphone, two monitors, and a current version of Windows. It is also recommended that you attend your virtual class in a
                    quite location where background noise will be limited and you will experience less interruptions. All of these factors will
                    help set you up for a successful training experience.
                </p>{" "}
                <p>
                    If you have specific questions about equipment or software compatibility, please send us a message through the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        {" "}
                        AlertonTraining.com Contact
                    </a>{" "}
                    page.
                </p>
                <h3 className="subtitle">Do you offer classes in different languages?</h3>
                <p>At this time, we are only able to offer our courses in English.</p>
                <h3 className="subtitle">How long does each class take?</h3>
                <p>The Compass 2.0 Operator Class is a 2-day course. Students should plan for each day of training to last a full 8-hours.</p>
                <p>
                    The Ascent Engineering &amp; Commissioning Certification is a 5-day course. Students should plan for each day of training to
                    last a full 8-hours.
                </p>
                <p>
                    The Ascent Programming Certification is a 5-day course. Students should plan for each day of training to last a full 8-hours.
                </p>
                <p>There is no difference in duration whether the class is conducted in-person or virtually.</p>
                <h3 className="subtitle">Do you offer custom training solutions?</h3>
                <p>
                    Our instructor-led training courses are crafted to provide the best learning experience possible for operators, technicians,
                    engineers, and facilities personnel. However, if you have questions about the course content and what you can expect, please
                    send us a message from the{" "}
                    <a href="https://alertontraining.com/contact" target="_blank" rel="noreferrer">
                        AlertonTraining.com Contact
                    </a>{" "}
                    page. We would love to discuss all available training options with you.
                </p>
                <h3 className="subtitle">I just completed a class -- when can I expect to receive my score and certificate?</h3>
                <p>
                    You will receive your final exam score within (5) business days of course completion. Upon successful completion of the
                    certification course, a certificate will be issued within (20) business days.
                </p>
                <h3 className="subtitle">Do you train on Legacy Alerton systems?</h3>
                <p>
                    Currently we only train on the Ascent Compass 2.0 software and user interface, however we include instruction and information
                    about legacy Alerton controllers and components in each of our classes. Our instructors also work hard to answer any
                    questions about legacy systems and help connect students with the best tools and processes to help them integrate and become
                    skillful with the most current versions of the Ascent software suite.
                </p>
            </article>
        </Layout>
    );
}

export default FAQ;
