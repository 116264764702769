import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { ICourseLocationsResponse } from "../interfaces/serviceResponses/course/ICourseLocationsResponse";
import { GetCourseInstanceDataResponse, ICourses, ICoursesResponse } from "../interfaces/serviceResponses/course/ICoursesResponse";
import { ICourseTypesResponse } from "../interfaces/serviceResponses/course/ICourseTypesResponse";

class CourseService extends ApiBaseService {
    public static async getCourses(limit, offset): Promise<IResponse<ICoursesResponse>> {
        const response = await this.post<ICoursesResponse>(`course-instance`, { limit, offset });
        if (response.success && response != null && response.response) {
            return response;
        } else {
            return response;
        }
    }

    public static async getCoursesWithDate(startDate?: string): Promise<IResponse<ICourses[]>> {
        const response = await this.post<ICourses[]>(`course-instance/courses-date`, { startDateFrom: startDate });
        if (response.success && response != null && response.response) {
            return response;
        } else {
            return response;
        }
    }
    public static async getCourseTypes(): Promise<IResponse<ICourseTypesResponse>> {
        const response = await this.get<ICourseTypesResponse>(`course`);
        if (response.success && response != null && response.response) {
            for (let course of response.response.items) {
                const location = await this.getCourseLocation(course.id);
                course.locations = location.response.locations.map(loc => {
                    return {
                        label: loc.name,
                        value: loc.id.toString(),
                        selected: false,
                    };
                });
            }
            return response;
        } else {
            return response;
        }
    }

    public static async getCourseLocation(courseId: number): Promise<IResponse<ICourseLocationsResponse>> {
        const response = await this.post<ICourseLocationsResponse>(`checkout/dropdownlocations`, { courseId: courseId });
        if (response.success && response != null && response.response) {
            return response;
        } else {
            return response;
        }
    }

    public static async getData(id): Promise<IResponse<GetCourseInstanceDataResponse>> {
        const response = await this.get<GetCourseInstanceDataResponse>(`course-instance/data/${id}`);
        if (response.success && response != null && response.response) {
            return response;
        } else {
            console.error("Error: ", response.message);
            return response;
        }
    }
}

export default CourseService;
