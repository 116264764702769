import Layout from "../../components/Layout";
import Hero from "../../components/Shared/Hero";
import styles from "./home.module.scss";
import compass_logo from "../../assets/media/compass_operator_logo.svg";
import engineering_logo from "../../assets/media/ascent_engineering_logo.svg";
import programming_logo from "../../assets/media/ascent_programming_logo.svg";
import path_detail_01 from "../../assets/media/path_detail_01.svg";
import path_detail_02 from "../../assets/media/path_detail_02.svg";
import path_detail_03 from "../../assets/media/path_detail_03.svg";
import path_detail_04 from "../../assets/media/path_detail_04.svg";
import path_detail_05 from "../../assets/media/path_detail_05.svg";
import path_detail_06 from "../../assets/media/path_detail_06.svg";
import path_arrow from "../../assets/media/path_arrow.svg";
import { useNavigate } from "react-router-dom";
import ATSCoursesTiles from "../../components/Shared/ATSCoursesTile";
import { useEffect, useState } from "react";
import { tileColorDict, logoDict } from "../../helpers/coursesDictionaries";
import { ICourses } from "../../services/interfaces/serviceResponses/course/ICoursesResponse";
import CourseService from "../../services/entitiesServices/CoursesService";
import ATSSpinner from "../../components/Shared/ATSSpinner";

function Home() {
    const navigate = useNavigate();
    const [courses, setCourses] = useState<ICourses[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const heroTitle = "ALERTON TRAINING";
    const heroDescription = `Welcome to the official Alerton Training website. We provide in-person and virtual instructor-led training for building automation companies and professionals. 
    
    Our certified training courses help students learn how to operate their Alerton systems with greater efficiency, confidence, and skill. Click below to get started!`;

    const defaultText = "There are no courses available at this moment.";

    const ctaElement = {
        label: "All courses",
        url: "/courses",
    };

    async function getCourses() {
        const coursesData = await CourseService.getCoursesWithDate();
        if (coursesData && coursesData.success) {
            setCourses(coursesData.response);
            setLoading(false);
        }
    }

    useEffect(() => {
        getCourses();
    }, []);

    const sortedCourses: ICourses[] = [...courses];
    sortedCourses.sort((a, b) => a.priority - b.priority);

    return (
        <Layout title={"Home"}>
            <Hero title={heroTitle} description={heroDescription} cta={ctaElement}></Hero>

            <section className={styles.ourCourses}>
                <div className={styles.ourCourses_container}>
                    <h2 className={styles.ourCourses_title}>Our Courses</h2>

                    <div className={styles.ourCourses_content}>
                        <article className={styles.ourCourses_card} onClick={() => navigate("/courses#compass")}>
                            <h3 className={styles.ourCourses_card_title}>Compass Operator Training</h3>
                            <div className={styles.ourCourses_card_content}>
                                <p>
                                    Learn about Alerton building automation concepts and become familiar navigating and interfacing with Compass
                                    2.0. Students taking this class will explore the most commonly used Compass tools and features and learn how
                                    to maximize the use of their system.
                                </p>
                            </div>
                            <div className={styles.ourCourses_card_logo}>
                                <img src={compass_logo} alt="Compass Operator Training" />
                            </div>
                            <i className="icon-arrow_back" title="go to"></i>
                        </article>
                        <article className={styles.ourCourses_card} onClick={() => navigate("/courses#alerton-engineering")}>
                            <h3 className={styles.ourCourses_card_title}>Alerton Engineering & Commissioning Certification</h3>
                            <div className={styles.ourCourses_card_content}>
                                <p>
                                    Take your training to the next level by learning how to design, develop, and commission an Alerton Compass
                                    2.0 system. Students taking this class will simulate an actual installation and configuration of a new
                                    project.
                                </p>
                            </div>
                            <div className={styles.ourCourses_card_logo}>
                                <img src={engineering_logo} alt="Alerton Engineering & Commissioning Certification" />
                            </div>
                            <i className="icon-arrow_back" title="go to"></i>
                        </article>
                        <article className={styles.ourCourses_card} onClick={() => navigate("/courses#alerton-programming")}>
                            <h3 className={styles.ourCourses_card_title}>Alerton Programming Certification Training</h3>
                            <div className={styles.ourCourses_card_content}>
                                <p>
                                    Become familiar with DDC programming and development. Students taking this course will learn how to read,
                                    edit, and create code for a variety of Alerton DDC devices.
                                </p>
                            </div>
                            <div className={styles.ourCourses_card_logo}>
                                <img src={programming_logo} alt="Alerton Programming Certification Training" />
                            </div>
                            <i className="icon-arrow_back" title="go to"></i>
                        </article>
                    </div>
                </div>
            </section>

            <section className={styles.atsPath}>
                <div className={styles.atsPath_container}>
                    <div className={styles.atsPath_top}>
                        <p>Recommended</p>
                        <h2>Alerton Training Path</h2>
                    </div>

                    <div className={styles.atsPath_content}>
                        <article className={styles.atsPath_item}>
                            <div className={styles.atsPath_item_content}>
                                <h3 className={styles.atsPath_item_title}>Compass operator training</h3>

                                <span className={styles.atsPath_item_circle} title="2 days">
                                    {" "}
                                </span>
                            </div>
                        </article>

                        <article className={styles.atsPath_item}>
                            <div className={styles.atsPath_item_content}>
                                <h3 className={styles.atsPath_item_title}>Alerton engineering and commissioning</h3>

                                <span className={styles.atsPath_item_circle} title="1 week"></span>

                                <div className={styles.atsPath_item_tooltip}>
                                    <i className="icon-info"></i>
                                    <div className={styles.atsPath_item_tooltip_box}>
                                        <p>Classroom Exam:</p>
                                        <p>Alerton Certified Engineer</p>
                                    </div>
                                </div>
                            </div>
                            <img className={styles.atsPath_item_arrow} src={path_arrow} alt="Home page detail arrow" />
                        </article>

                        <article className={styles.atsPath_item}>
                            <div className={styles.atsPath_item_content}>
                                <h3 className={styles.atsPath_item_title}>Alerton Programming</h3>

                                <span className={styles.atsPath_item_circle} title="1 week"></span>

                                <div className={styles.atsPath_item_tooltip}>
                                    <i className="icon-info"></i>
                                    <div className={styles.atsPath_item_tooltip_box}>
                                        <p>Classroom Exam:</p>
                                        <p>Alerton Certified Programmer</p>
                                    </div>
                                </div>
                            </div>
                            <img className={styles.atsPath_item_arrow} src={path_arrow} alt="Home page detail arrow" />
                        </article>
                    </div>

                    <div className={styles.atsPath_bottom}>
                        <span className={styles.atsPath_bottom_title}>Classroom Exam</span>
                        <div className={styles.atsPath_bottom_area}>
                            <i className="icon-straight"></i>
                            <p>Alerton Certified Engineer</p>
                        </div>
                        <div className={styles.atsPath_bottom_area}>
                            <i className="icon-straight"></i>
                            <p>Alerton Certified Programmer</p>
                        </div>
                    </div>
                </div>

                <img className={styles.atsPath_detail} src={path_detail_01} alt="Home page path detail" />
                <img className={styles.atsPath_detail} src={path_detail_02} alt="Home page path detail" />
                <img className={styles.atsPath_detail} src={path_detail_03} alt="Home page path detail" />
                <img className={styles.atsPath_detail} src={path_detail_04} alt="Home page path detail" />
                <img className={styles.atsPath_detail} src={path_detail_05} alt="Home page path detail" />
                <img className={styles.atsPath_detail} src={path_detail_06} alt="Home page path detail" />
            </section>

            <section className={styles.ourCourses}>
                <div className={styles.ourCourses_container}>
                    <h2 className={styles.ourCourses_title}>Upcoming Training</h2>

                    {!loading ? (
                        <div className={styles.ourCourses_contentTile}>
                            {sortedCourses.map(course => (
                                <ATSCoursesTiles
                                    key={course.value}
                                    data={course.courses[0]}
                                    color={tileColorDict[course.value]}
                                    image={logoDict[course.value]}
                                    title={course.name}
                                    value={course.value}
                                    text={defaultText}></ATSCoursesTiles>
                            ))}
                        </div>
                    ) : (
                        <div className={styles.loadingContainer}>
                            <div className={styles.ats_layoutSpinnerContainer}>
                                <ATSSpinner title="Loading..." />
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <section className={styles.needHelp}>
                <div className={styles.needHelp_content}>
                    <div className={styles.needHelp_box}>
                        <div className={styles.needHelp_box_texts}>
                            <h2>Need help?</h2>
                            <p>Send us a message.</p>
                        </div>

                        <button onClick={() => navigate(`/contact`)}>Contact</button>
                    </div>

                    <span className={styles.needHelp_details}></span>
                    <span className={styles.needHelp_details_mobile}></span>
                </div>
            </section>
        </Layout>
    );
}

export default Home;
