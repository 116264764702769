import { ATSSteps, IBaseStepProps } from "..";
import ATSRadioInput, { ATSRadioType } from "../../../components/form/ATSRadioInput";
import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import { currencyDollarUS, IObjectValidationsProperties, objectValidations } from "../../../helpers";
import { PaymentMethods } from "../../../services/interfaces/serviceRequests/payment/IAddPaymentDealerRequest";
import AchMethod from "./Ach";
import CreditCard from "./CreditCard";
import styles from "./PaymentStep.module.scss";
interface IPaymentStepProps extends IBaseStepProps {
    handleSubmit: () => void;
    setEnrollData: (state: any) => void;
    restorePayMethods: (method: PaymentMethods) => void;
    price: number | null;
    loading: boolean;
}

function PaymentStep(props: IPaymentStepProps) {
    const { handleInputChange, state, handleErrorsChange, errors, handleStep, handleSubmit, restorePayMethods, price, loading } = props;

    const paymentOptions = [
        {
            label: "Credit Card",
            value: PaymentMethods.CreditCard,
            classIcon: "icon-credit_card",
            default: true,
        },
        {
            label: "ACH",
            value: PaymentMethods.ACH,
            classIcon: "icon-account_balance_wallet",
            default: false,
        },
    ];

    const paymentForms = {
        1: <CreditCard {...{ handleInputChange, state, handleErrorsChange, errors }} />,
        2: <AchMethod {...{ handleInputChange, state, handleErrorsChange, errors }} />,
    };

    const disablePayButton = () => {
        switch (state.method) {
            case PaymentMethods.CreditCard:
                return validateCardData();
            case PaymentMethods.ACH:
                return validateAchData();
        }
    };

    const validateCardData = (): boolean => {
        const validationsCardInputs: Array<IObjectValidationsProperties> = [
            {
                key: "cardNumber",
                type: "string",
                required: true,
            },
            {
                key: "cvc",
                type: "string",
                required: true,
            },
            {
                key: "expirationMonth",
                type: "number",
                required: true,
            },
            {
                key: "expirationYear",
                type: "number",
                required: true,
            },
            {
                key: "name",
                type: "string",
                required: true,
            },
            {
                key: "country",
                type: "string",
                required: true,
            },
            {
                key: "postalCode",
                type: "string",
                required: true,
            },
        ];
        if (!state.paymentData) return false;
        const properties = objectValidations(state.paymentData, validationsCardInputs);
        if (errors.creditCard) {
            if (errors.creditCard.cardNumber?.length > 0 ||
                errors.creditCard.cvc?.length > 0 ||
                errors.creditCard.expiration?.length > 0 ||
                errors.creditCard.name?.length > 0 ||
                errors.creditCard.postalCode?.length > 0) return false;
        }
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) return true;
        else {
            return false;
        }
    };

    const validateAchData = (): boolean => {
        const validationsACHInputs: Array<IObjectValidationsProperties> = [
            {
                key: "accountNumber",
                type: "string",
                required: true,
            },
            {
                key: "routingNumber",
                type: "string",
                required: true,
            },
            {
                key: "holderName",
                type: "string",
                required: true,
            },
            {
                key: "accountHolderType",
                type: "string",
                required: true,
            },
        ];
        if (!state.paymentData) return false;
        if (state?.paymentData?.accountNumber && state?.paymentData?.accountNumber.length != 12) return false;
        if (state?.paymentData?.routingNumber && state?.paymentData?.routingNumber.length != 9) return false;
        const properties = objectValidations(state.paymentData, validationsACHInputs);
        if (properties && properties.hasRequiredInputs && properties.passTypeValidations) return true;
        else {
            return false;
        }
    };

    return (
        <fieldset className="fieldset">
            <h2 className="form_title">Payment Information</h2>
            <ATSRadioInput name="method" type={ATSRadioType.CARD} options={paymentOptions} handleChange={handleInputChange} />
            {paymentForms[state.method]}
            <div className={`btn-group ${styles.buttonsWrapper}`}>
                <ATSButton
                    cta="Back"
                    type={ATSTypeButton.OUTPOSITIVE}
                    action={() => {
                        handleStep(ATSSteps.BACK);
                        restorePayMethods(state.method);
                    }}
                />
                <ATSButton
                    cta={`${loading ? `Payment is processing... ` : `Pay ${price && currencyDollarUS.format(price)}`}`}
                    type={ATSTypeButton.PAY}
                    action={handleSubmit}
                    iconClass={`${loading ? "" : "icon-arrow_back"}`}
                    disabled={!disablePayButton()}
                    loading={loading}
                />
            </div>
        </fieldset>
    );
}

export default PaymentStep;
