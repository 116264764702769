import { useEffect, useState } from "react";
import { ATSSteps, IBaseStepProps } from "..";
import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import { copyJSON } from "../../../helpers";
import StudentForm from "./StudentForm";
import styles from "./StudentsStep.module.scss";
interface IStudentStepProps extends IBaseStepProps {
    setCheckoutData: (data) => void;
}

function StudentsStep(props: IStudentStepProps) {
    const { handleInputChange, state, handleErrorsChange, errors, handleStep, setCheckoutData } = props;
    const { students } = state;
    const [validatonsPassed, setValidationsPassed] = useState<boolean>(false);

    const manageStudents = (index, name, value) => {
        let mutateState = copyJSON(students);
        mutateState[index] = {
            ...mutateState[index],
            [name]: value,
        };
        handleInputChange("students", mutateState);
    };

    //Validations to disable next button
    useEffect(() => {
        const hasData = students.every(x => x.firstName !== "" && x.lastName !== "" && x.phoneNumber !== "" && x.email !== "");
        const hasErrors: Array<boolean> = [];
        if (errors && errors.students) {
            errors.students.forEach(error => {
                const errorsInStudents = error ? Object.keys(error).every(x => error[x].length === 0) : true;
                hasErrors.push(errorsInStudents);
            });
        }
        hasData && !hasErrors.includes(false) ? setValidationsPassed(true) : setValidationsPassed(false);
    }, [students, errors]);

    const handleDeleteStudent = (index: number): void => {
        let mutateState = copyJSON(students);
        //Can delete from students array until it has one student
        if (mutateState.length > 1) {
            mutateState.splice(index, 1);
            setCheckoutData(prev => ({ ...prev, students: mutateState, slots: mutateState.length }));
        }
    };

    return (
        <fieldset className="fieldset">
            <h2 className="form_title">Enroll new students</h2>
            <ul className={styles.accordion}>
                {students.map((_s, i) => (
                    <StudentForm
                        {...{ manageStudents, students, errors, handleErrorsChange, handleDeleteStudent }}
                        index={i}
                        key={`student_form_${i}`}
                    />
                ))}
            </ul>
            <div className="btn-group">
                <ATSButton cta="Back" type={ATSTypeButton.OUTPOSITIVE} action={() => handleStep(ATSSteps.BACK)} />
                <ATSButton cta="Next" type={ATSTypeButton.POSITIVE} action={() => handleStep(ATSSteps.NEXT)} disabled={!validatonsPassed} />
            </div>
        </fieldset>
    );
}

export default StudentsStep;
