import Layout from "../../components/Layout"
import HeroMini from "../../components/Shared/HeroMini";

function TermsConditions() {
    return (
        <Layout title={"Terms and Conditions"}>
            <HeroMini title={"Terms of Services"}></HeroMini>
            <article className="onlyText">
                <h2 className="title">ALERTON TRAINING COURSES TERMS OF USE</h2>
            
                <p>
                    The Alerton Factory Training Terms of Use ("Terms of Use") govern your use of the Alerton Factory Training (“Training”), including all related documentation and materials made available to you by A.T.S. Automation, Inc. ("ATS") and Alerton Technologies (“Alerton”). The use of the Training and all related documentation and materials is pursuant to and subject to an agreement between ATS and Alerton and the Alerton entity or dealer ("Dealer") that provided you with access to the Training (the "Training Agreement").
                </p>
                <p>
                1) Confidentiality Notice: The Training provided, along with any and all related materials, is confidential to ATS Automation and Alerton Technologies (“ATS and Alerton”). Such information and the software described therein the training, is furnished under a license agreement  and may be used only in accordance with that agreement. 
                </p>
                <p>
                2) Use Restrictions: The  information  contained  in  the training  is  provided  solely  for  use  by  ATS and Alerton employees,  dealers,  and system  owners;  and is  not  to  be  released  to,  or reproduced for, anyone else without express written permission of ATS and Alerton. While  every  effort  has  been  made  to  assure  the  accuracy  of  this  Training,  ATS and Alerton are not  responsible  for damages  of  any  kind,  including  without  limitation,  consequential  damages  arising  from  the  application  of  the information  contained  therein.  Information  and  specifications  published  in the Training   are  current  as  of  the  date  of  this agreement  and  are  subject  to  change  without  notice.
                </p>
                <p>
                3) Copyright Notice: This factory training  may  not, in whole  or  in  part,  be  copied,  photocopied,  reproduced,  translated,  or  reduced  to  any  electronic  medium  or machine-readable form without prior written consent from ATS and Alerton. No materials available on the Alerton Training Website may be stored, transmitted by any means (including but not limited to electronic, mechanical, scanning, photocopying, or recording) without prior written permission of ATS and Alerton.
                </p>
                <p>
                4) Feedback: Should you view the Alerton Training website or participate in the Training and respond with information (excluding personal information, which is addressed under the Internet Privacy Policy) including questions, comments or suggestions regarding the content of the website or Training, such information shall be deemed to be non-confidential and ATS and Alerton shall have no obligation of any kind with respect to such information and shall be free to reproduce, use, disclose and distribute the information to others without limitation. ATS and Alerton shall be free to use any ideas, concepts, know-how or techniques contained in such information for any purpose whatsoever including but not limited to developing, manufacturing and marketing products incorporating such information.
                </p>
                <p>
                5) Disclaimer of Liability: ALERTON TRAINING MAKES NO REPRESENTATIONS OR WARRANTIES, EITHER EXPRESS OR IMPLIED, OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR OF ANY NATURE REGARDING THE INFORMATION, MATERIALS OR SERVICES PROVIDED ON THIS WEBSITE OR ANY PRODUCT TO WHICH SUCH INFORMATION OR MATERIALS REFER, OR TO WHICH SUCH INFORMATION, MATERIALS OR SERVICES RELATE. The information on this website and instructed upon within the Training may include typographical inaccuracies and it may be out of date. Under no circumstances will ATS and Alerton be liable to any person or business entity for any direct, indirect, special, incidental, consequential or other damages based on any use of this website or use of the content instructed upon within the training, including, without limitation, any lost profits, business interruption, or loss of programs or information, even if ATS and Alerton has been specifically advised of the possibility of such damages.
                </p>
                <p>
                6) Aggregated Statistics: ATS may monitor your use of the Training and collect and compile data and information related to your use of the Training in an aggregated and anonymized manner ("Aggregated Statistics"). As between you and ATS, ATS will own all right, title, and interest in Aggregated Statistics, and all intellectual property rights therein. ATS may use Aggregated Statistics for any purpose permitted by law.
                </p>
                <p>
                7) Access to Website and Content: We may, in our sole discretion, terminate or suspend your access to all or any part of this site if we believe that you have violated any of these Terms of Use, or that you have conducted any fraudulent, abusive or offensive activity by means of any public area of this site. We shall have no liability to you or any other party for any claim or damage arising as a result of such termination.

                </p>
            </article>
        </Layout>
    );
  }
  
  export default TermsConditions