import React, { PropsWithChildren, ReactElement, useEffect } from "react";
import ATSErrorMsg from "../ATSErrorMsg";
import styles from "./ATSZipCodeInput.module.scss";

interface IATSZipCodeInputProps {
    name: string;
    value: string;
    country?: string;
    label?: string;
    required?: boolean;
    placeholder?: string;
    error?: string[];
    setErrorMessage?: (name: string, value: string[], _indexed?: string, _index?: number) => void;
    handleChange: (name: string, value: string) => void;
    maxLengthProps?: number;
}

/**
 * Create custom zip code input. Supports 5 digit zip codes only.
 * @param name string ;
 * @param value string;
 * @param label string ;
 * @param placeholder string ;
 * @param required boolean; -> Activate error on click outside withot value
 * @param error Array<string>; -> optional
 * @param setErrorMessage ( )=> void -> setState function that recieves a name and value;
 * @param handleChange ( )=> void -> setState function that recieves a name and value;
 */

const IATSZipCodeInput = (props: PropsWithChildren<IATSZipCodeInputProps>): ReactElement => {
    const { name, value, label, required, placeholder, error, setErrorMessage, handleChange, maxLengthProps, country } = props;

    useEffect(() => {
        if (!country) return;
        handleBlur(value);
    }, [country]);


    /**
     *Canada use a 6 digit postal code number having numbers and letters.
     * With this regex we can validate if user value match the value with these different ways to set postal code
     *  XNX-NXN
     *  XNX NXN
     *  XNXNXN
     */
    function matchCanadaRegex() {
        var regex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i;
        var match = regex.exec(value);
        if (match) return [];
        else return ["Invalid Zip Code"];
    }

    /**
     * Replace special caracters but accept space to separate value
     * @param val string; input value 
     * @returns replaced string
     */
    function replaceCanadaString(val: string) {
        let maxLenght = 6;
        if (val.includes('-') || val.includes(' ')) maxLenght = 7;

        let replaced = val.replace(/[^0-9A-Za-z \.]+/g, "");
        if (replaced.length > maxLenght) {
            replaced = replaced.slice(0, maxLenght);
        }

        return replaced;
    }

    function validate(val: string): Array<string> {
        switch (country) {
            case 'CA':
                return matchCanadaRegex();
            default:
                if (val.length === 5) return [];
                else return ["Invalid Zip Code"];
        }
    }

    function format(val: string): string {
        switch (country) {
            case 'CA':
                return replaceCanadaString(val);
            default:
                let replaced = val.replace(/[^0-9\.]+/g, "");
                if (replaced.length > 5) {
                    replaced = replaced.slice(0, 5);
                }
                return replaced;
        }
    }


    function handleInputChange(value: string) {
        let formattedValue = format(value);
        setErrorMessage && setErrorMessage(name, []);
        handleChange(name, formattedValue);
    }

    const handleBlur = (val: string) => {
        if (val === "" && required) {
            setErrorMessage && setErrorMessage(name, [`This field is mandatory and you did not provide a value.`]);
        } else {
            const msg = validate(val);
            setErrorMessage && setErrorMessage(name, msg);
        }
    };

    return (
        <div className={`form_item ${styles.wrapper} ${error && error?.length > 0 ? "danger" : ""}`}>
            {label && <label>{label}</label>}
            <input
                className={styles.wrapper_input}
                type="text"
                onChange={e => handleInputChange(e.target.value)}
                onBlur={e => handleBlur(e.target.value)}
                name={name}
                value={value}
                placeholder={placeholder}
                maxLength={maxLengthProps ?? 100}
            />
            {error && error?.length > 0 && <ATSErrorMsg errors={error} />}
        </div>
    );
};

export default IATSZipCodeInput;
