import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import ATSSpinner from "../../components/Shared/ATSSpinner";
import { copyJSON, currencyDollarUS } from "../../helpers";
import PaymentService from "../../services/entitiesServices/PaymentService";
import { IAddPaymentDealerRequest } from "../../services/interfaces/serviceRequests/payment/IAddPaymentDealerRequest";
import { GetCourseInstanceDataResponse } from "../../services/interfaces/serviceResponses/course/ICoursesResponse";
import styles from "./Checkout.module.scss";
import CourseStep from "./CourseStep";
import PaymentStep from "./PaymentStep";
import PaymentSuccess from "./PaymentSuccess";
import StepsIndicator from "./StepIndicator";
import StudentsStep from "./StudentsStep";
import Summary from "./Summary";
import detail_bg_top from "../../assets/media/checkout_bg02.svg";
import detail_bg_bottom from "../../assets/media/checkout_bg01.svg";
import CheckoutFail from "./PaymentFail";
import { ATSTypeButton, IATSButtons } from "../../components/Shared/ATSButton";

export enum ATSSteps {
    NEXT,
    BACK,
}

export interface IBaseStepProps {
    handleInputChange: (name: string, value: string | number, _indexed?: string, _index?: number) => void;
    handleErrorsChange: (name: string, value: string[], _indexed?: string, _index?: number) => void;
    handleStep: (direction: ATSSteps) => void;
    state: any;
    errors: any;
}

export enum PaymentMethods {
    CreditCard = 1,
    ACH = 2,
    Manual = 3,
}

export enum PaymentMethodsString {
    CreditCard = "creditCard",
    ACH = "ach",
    Manual = "manual",
}

export interface ICourseCheckout {
    courseId: number;
    name: string;
    locationName: string;
    locationValue: string;
    seats: number;
}

function Checkout() {
    //These statuses come from the previous page...maybe in the location {state} dynamic
    const [courseId, setCourseId] = useState<number>(0);
    const [locationId, setLocation] = useState<number>(0);
    const [dealer, setDealer] = useState<any | null>(null);
    const [errors, setErrors] = useState<any>({});
    const [steps, setSteps] = useState<number>(0);
    const [selectedInstance, setSelectedInstance] = useState<GetCourseInstanceDataResponse | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [enrolledSucces, setEnrolledSucces] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const locationHook = useLocation();
    const locationState = locationHook.state as any;
    const navigate = useNavigate();
    //Should be initialized with one type of payment, and send only one type of data for the payment.
    const [checkoutData, setCheckoutData] = useState<IAddPaymentDealerRequest>({
        courseInstanceId: null,
        token: "",
        slots: locationState?.courseInfo?.seats ?? 0,
        students: [],
        method: 1,
        paymentData: {
            cardNumber: "",
            cvc: "",
            expirationMonth: null,
            expirationYear: null,
            name: "",
            country: "US",
            postalCode: "",
        },
    });

    useEffect(() => {
        if (!locationState) {
            return navigate('/404');
        }
        const courseInfo = locationState.courseInfo;
        setCourseId(courseInfo.courseId);
        setLocation(courseInfo.locationValue);
        setCheckoutData({ ...checkoutData, slots: courseInfo?.seats });
    }, []);

    //set students by slots quantity
    useEffect(() => {
        if (checkoutData.slots !== 0 && !checkoutData.slots) return;
        let quantity: number = checkoutData.slots;
        //add slots if its true, else remove
        let mutateState = copyJSON(checkoutData.students);

        for (let i = 0; i < quantity; i++) {
            mutateState = [
                ...mutateState,
                {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                },
            ];
        }

        setCheckoutData({
            ...checkoutData,
            students: mutateState,
        });
    }, []);

    const handleInputChange = (name: string, value: any, _indexed?: string, _index?: number): void => {
        if (name === "method" && checkoutData.method !== value) restorePayMethods(value);

        if (!_indexed) {
            setCheckoutData(prev => ({
                ...prev,
                [name]: value,
            }));
        } else if (_indexed && (_index || _index === 0)) {
            let mutateState;
            let mutateIndexed;

            if (checkoutData[_indexed]) {
                mutateIndexed = copyJSON(checkoutData[_indexed]);
            } else {
                mutateState = copyJSON(checkoutData);
                mutateState[_indexed] = [];
                mutateIndexed = copyJSON(mutateState[_indexed]);
            }

            mutateIndexed[_index] = {
                ...mutateIndexed[_index],
                [name]: value,
            };

            setCheckoutData(prev => ({
                ...prev,
                [_indexed]: mutateIndexed,
            }));
        }
    };

    const handleErrorsChange = (name: string, value: string[], _indexed?: string, _index?: number): void => {
        if (!_indexed) {
            setErrors(prev => ({
                ...prev,
                [name]: value,
            }));
        } else if (_indexed && (_index || _index === 0)) {
            let mutateState;
            let mutateIndexed;

            if (errors[_indexed]) {
                mutateIndexed = copyJSON(errors[_indexed]);
            } else {
                mutateState = copyJSON(errors);
                mutateState[_indexed] = [];
                mutateIndexed = copyJSON(mutateState[_indexed]);
            }

            mutateIndexed[_index] = {
                ...mutateIndexed[_index],
                [name]: value,
            };

            setErrors(prev => ({
                ...prev,
                [_indexed]: mutateIndexed,
            }));
        }
    };

    const handleStep = (direction: ATSSteps) => {
        if (direction === ATSSteps.NEXT) {
            setSteps(prev => prev + 1);
        } else {
            setSteps(prev => prev - 1);
        }
    };

    const handleSubmit = async () => {
        setLoading(true);
        try {
            let payload = copyJSON(checkoutData) as any;

            switch (payload.method) {
                case PaymentMethods.CreditCard: {
                    payload["creditCard"] = payload.paymentData;
                    break;
                }
                case PaymentMethods.ACH: {
                    payload["ach"] = payload.paymentData;
                    break;
                }
                case PaymentMethods.Manual: {
                    payload["manual"] = payload.paymentData;
                    break;
                }
            }

            delete payload["paymentData"];

            const response = await PaymentService.payDealer(payload as IAddPaymentDealerRequest);
            if (response.success) {
                if (response.message.length > 0) {
                    alert(`${response.message}`);
                } else {
                    setEnrolledSucces(response.response);
                    setSteps(3);
                }
            } else {
                setSteps(4);
                setErrorMessage(response.message);
            }
        } catch (e) {
            //do something
        }
        setLoading(false);
    };

    const restorePayMethods = (method: PaymentMethods) => {
        let mutateState = copyJSON(checkoutData);
        switch (method) {
            case PaymentMethods.CreditCard: {
                mutateState = {
                    ...mutateState,
                    paymentData: {
                        ...mutateState["paymentData"],
                        name: "",
                        cardNumber: "",
                        cvc: "",
                        expirationMonth: null,
                        expirationYear: null,
                        country: "US",
                        postalCode: "",
                    },
                };
                break;
            }
            case PaymentMethods.ACH: {
                mutateState = {
                    ...mutateState,
                    paymentData: {
                        accountNumber: "",
                        routingNumber: "",
                        holderName: "",
                        accountHolderType: "",
                        ipAddress: "",
                        userAgent: "",
                    },
                };
                break;
            }
            case PaymentMethods.Manual: {
                mutateState = {
                    ...mutateState,
                    paymentData: {
                        title: "",
                        comment: "",
                    },
                };
                break;
            }
        }

        setCheckoutData(mutateState);
    };

    const componentSteps = {
        0: (
            <CourseStep
                state={checkoutData}
                {...{
                    handleInputChange,
                    handleErrorsChange,
                    handleStep,
                    selectedInstance,
                    setSelectedInstance,
                    errors,
                    locationId,
                    courseId,
                    setDealer,
                }}
            />
        ),
        1: (
            <StudentsStep
                state={checkoutData}
                {...{ handleInputChange, handleErrorsChange, handleStep, selectedInstance, setSelectedInstance, errors, setCheckoutData }}
            />
        ),
        2: (
            <PaymentStep
                setEnrollData={setCheckoutData}
                state={checkoutData}
                {...{ handleInputChange, handleErrorsChange, handleStep, errors, handleSubmit, restorePayMethods }}
                price={selectedInstance ? selectedInstance?.price * checkoutData.slots : null}
                loading={loading}
            />
        ),
    };

    const buttonGoBackToStep0: Array<IATSButtons> = [
        {
            cta: "Go back",
            type: ATSTypeButton.DANGER,
            action: () => setSteps(0),
        },
    ];

    return (
        <Layout title={"Checkout"}>
            {steps !== 3 && steps !== 4 && (
                <div className={styles.layout}>
                    <section className={styles.layout_steps}>
                        <div>
                            <StepsIndicator totalSteps={["Course", "Students", "Payment"]} actualStep={steps} />
                        </div>
                    </section>

                    <aside className={styles.layout_aside}>
                        <Summary
                            course={locationState?.courseInfo?.name ?? "Some Course"}
                            location={locationState?.courseInfo?.locationName ?? "Online"}
                            seats={locationState?.courseInfo?.seats ?? checkoutData.slots}
                            dealer={dealer}
                            instance={selectedInstance}
                            students={checkoutData.students}
                        />
                    </aside>

                    <section className={styles.layout_content}>
                        <div className={styles.layout_content_box}>
                            <form className="form">{componentSteps[steps]}</form>
                        </div>
                    </section>

                    <img className={styles.layout_bg_top} src={detail_bg_top} />
                    <img className={styles.layout_bg_bottom} src={detail_bg_bottom} />
                </div>
            )}
            {steps === 3 && (
                <PaymentSuccess state={checkoutData} {...{ enrolledSucces, selectedInstance, dealer, courseInfo: locationState?.courseInfo }} />
            )}
            {steps === 4 && <CheckoutFail error={errorMessage} buttons={buttonGoBackToStep0} />}
            {loading && steps !== 2 && <ATSSpinner />}
        </Layout>
    );
}

export default Checkout;
