import { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { IMenuLink } from "../../components/Shared/StickyMenu";
import Layout from "../../components/Layout";
import StickyMenu from "../../components/Shared/StickyMenu";
import HeroMini from "../../components/Shared/HeroMini";
import compass_logo from "../../assets/media/compass_operator_logo.svg";
import ascent_engineering_logo from "../../assets/media/ascent_engineering_logo.svg";
import ascent_programming_logo from "../../assets/media/ascent_programming_logo.svg";
import detail_bg_footer from "../../assets/media/course_detail_footer.svg";
import detail_bg_right from "../../assets/media/course_detail_right.svg";
import styles from "./courses.module.scss";
import CourseService from "../../services/entitiesServices/CoursesService";
import { ICourseTypesResponse } from "../../services/interfaces/serviceResponses/course/ICourseTypesResponse";
import { ICourseCheckout } from "../Checkout";
import ATSSpinner from "../../components/Shared/ATSSpinner";

enum ATSCourseHash {
    ASCENTPROGRAMMING = "alerton-programming",
    ASCENTENGINEERING = "alerton-engineering",
    COMPASS = "compass",
}

const coursesObj: ICourse[] = [
    {
        id: 1,
        name: "Alerton Programming Certification Training",
        hash: "alerton-programming",
        isLocationsOpen: false,
        locations: [],
        sylabus: "/docs/Ascent Programming Certification Course Syllabus 09.18.2023.pdf",
        seats: 0,
        description:
            "This course focuses on DDC programming understanding and development. Students will learn BACnet DDC fundamentals and gain practical experience interpreting, modifying, and creating programming for Alerton controllers. Programming concepts and exercises will cover Alerton Visual Logic Controllers, Global Controllers and sub-routines, and tuning of proportional-integration loops. /nSuccessful completion of the Ascent Programming course will result in the student gaining the Alerton Certified Programmer credential. This certification was created for building automation and control system application engineers and programmers. Prerequisite completion of the Ascent Engineering and Commissioning Certification within the last 2-years is required.",
        value: "alerton_programming_certification_training",
    },
    {
        id: 2,
        name: "Alerton Engineering & Commissioning Certification",
        hash: "alerton-engineering",
        isLocationsOpen: false,
        locations: [],
        sylabus: "/docs/Ascent Engineering and Commissioning Certification Course Syllabus 09.18.2023.pdf",
        seats: 0,
        description:
            "This course emphasizes the design, development, and commissioning of an Alerton Ascent system. Students will use mock plans, specifications, and training panels to learn the fundamental skills and software tools needed to engineer and commission an Alerton Compass project. This course includes a full simulation of actual design, install, and checkout. /nSuccessful completion of the Ascent Engineering and Commissioning course will result in the student gaining the Alerton Certified Engineer credential. This certification was created for building automation and control system application/field engineers who work with Alerton’s Ascent and BACtalk control systems. Prerequisite completion of the Compass Operator Training course or the online Alerton Ascent Fundamentals course within the last 2-years is required.",
        value: "alerton_engineering_&_commissioning_certification",
    },

    {
        id: 3,
        name: "Compass Operator Training",
        hash: "compass",
        sylabus: "/docs/Compass 2 Operator Training Syllabus 09.18.2023.pdf",
        isLocationsOpen: false,
        locations: [],
        seats: 0,
        description:
            "The course teaches the fundamental concepts and skills that a building operator needs to effectively interface with and maximize the full capability of their Alerton Ascent System. Students will learn Alerton building automation fundamentals, Compass workstation features and navigation, how to create and edit schedules, trendlogs, and alarms, as well as how to use the engineering software within Compass to update devices, send and save point data, and scan for communicating devices. /nCompass Operator Training is the first step in the Alerton training and certification path. It was created for building automation and control system operators, technicians, installers, engineers, programmers, and salespersons. No previous controls experience is required.",
        value: "compass_operator_training",
    },
];

interface ICourse {
    id: number;
    name: string;
    hash: string;
    locations: { label: string; value: string; selected?: boolean }[];
    isLocationsOpen: boolean;
    sylabus: string;
    price?: number;
    seats: number;
    description: string;
    value: string;
}

function Courses() {
    const [activeCourse, setActiveCourse] = useState<string>("");
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [menuLinks, setMenuLinks] = useState<IMenuLink[]>([]);
    const observer = useRef<IntersectionObserver | null>();
    const { hash } = useLocation();

    function populateCourses(courses: ICourseTypesResponse): ICourse[] {
        const courseTemp: ICourse[] = [];
        courses.items.forEach((course, index) => {
            const currentCourse = coursesObj.find(e => e.id === course.id) ?? coursesObj[index];
            courseTemp.push({
                id: course.id ?? currentCourse.id,
                name: course.name ?? currentCourse.name,
                hash: course.hash ?? currentCourse.hash,
                locations: course.locations ?? currentCourse.locations,
                isLocationsOpen: false,
                sylabus: course.sylabus ?? currentCourse.sylabus,
                price: course.price ?? currentCourse.price,
                seats: 0,
                description: course.description ?? currentCourse.description,
                value: course.value ?? currentCourse.value,
            });
        });
        return courseTemp;
    }

    function createMenuLinks(courses: ICourse[]) {
        const menuLinks: IMenuLink[] = [];
        courses
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .forEach(item => {
                menuLinks.push({
                    label: item.name,
                    route: "/courses",
                    hash: item.hash,
                });
            });
        return menuLinks;
    }

    const scrollToCourse = useCallback((): void => {
        const scrollArea = document.body;

        if (hash === "") {
            scrollArea.scrollTo(0, 0);
            return;
        }

        const id = hash.replace("#", "");
        const element = document.getElementById(id);

        if (!element) return;

        const headerHeight = document.querySelector("header")?.offsetHeight;
        const elementY = Math.round(element.getBoundingClientRect().top) - (headerHeight ?? 0);
        const y = elementY + scrollArea.scrollTop;
        scrollArea.scrollTo({ top: y, behavior: "smooth" });
    }, [hash]);

    function toggleVisibility(courseHash: string): void {
        setCourses(current =>
            current.map(course => {
                if (course.hash === courseHash) {
                    return { ...course, isLocationsOpen: !course.isLocationsOpen };
                }

                return course;
            })
        );
    }

    function handleSelection(courseHash: string, locationValue: string): void {
        setCourses(current =>
            current.map(course => {
                if (course.hash === courseHash) {
                    return {
                        ...course,
                        isLocationsOpen: !course.isLocationsOpen,
                        locations: course.locations.map(location => {
                            return { ...location, selected: location.value === locationValue };
                        }),
                    };
                }

                return course;
            })
        );
    }

    function setSeats(courseHash: string, add: boolean): void {
        setCourses(current =>
            current.map(course => {
                if (course.hash === courseHash) {
                    return {
                        ...course,
                        seats: add ? course.seats + 1 : course.seats === 0 ? course.seats : course.seats - 1,
                    };
                }

                return course;
            })
        );
    }

    function getLocationName(locations: { label: string; value: string; selected?: boolean }[]): string | null {
        const selectedLocation = locations.filter(location => location.selected);
        return selectedLocation.length > 0 ? selectedLocation[0].label : null;
    }

    function hasCheckoutInfo(courseHash: string): boolean {
        const selectedCourse = courses.filter(course => course.hash === courseHash);
        if (selectedCourse.length <= 0) return false;

        const selectedLocation = selectedCourse[0].locations.filter(location => location.selected);
        if (selectedLocation.length <= 0) return false;

        return !!selectedCourse[0].name && !!selectedLocation[0].label && !!selectedLocation[0].value && selectedCourse[0].seats > 0;
    }

    function getCheckoutInfo(courseHash: string): ICourseCheckout | void {
        const selectedCourse = courses.filter(course => course.hash === courseHash);
        if (selectedCourse.length <= 0) return;

        const selectedLocation = selectedCourse[0].locations.filter(location => location.selected);
        if (selectedLocation.length <= 0) return;

        return {
            name: selectedCourse[0].name,
            locationName: selectedLocation[0].label,
            locationValue: selectedLocation[0].value,
            courseId: selectedCourse[0].id,
            seats: selectedCourse[0].seats,
        };
    }

    useEffect(() => {
        /*
        Make API calls
        */
        if (menuLinks.length <= 0) {
            CourseService.getCourseTypes()
                .then(res => {
                    if (res.success) {
                        const coursesTemp = populateCourses(res.response);
                        const links = createMenuLinks(coursesTemp);
                        setCourses(coursesTemp);
                        setMenuLinks(links);
                        setTimeout(() => {
                            scrollToCourse();
                        }, 10);
                    }
                })
                .catch(err => console.log(err));
        } else {
            scrollToCourse();
        }
    }, [scrollToCourse, menuLinks.length]);

    useEffect(() => {
        /*
        Set observer
        */
        if (courses.length === 0 || observer.current) return;

        const callback = (entries: IntersectionObserverEntry[]): void => {
            const intersectingTarget = entries.find(x => x.isIntersecting);
            const id = intersectingTarget?.target?.id;
            if (id) setActiveCourse(intersectingTarget.target.id);
        };
        const root = {
            root: document.body,
            rootMargin: "150px",
            threshold: 0.6,
        };
        observer.current = new IntersectionObserver(callback, root);

        courses.forEach(course => {
            const courseEl = document.getElementById(course.hash);
            if (courseEl && observer.current) observer.current.observe(courseEl);
        });
        return () => {
            if (observer.current) {
                observer.current.disconnect();
                observer.current = null;
            }
        };
    }, [hash, courses]);

    return (
        <Layout title={"Training Courses"}>
            <HeroMini title={"Training Courses"}></HeroMini>
            <div className={styles.layout}>
                {courses?.length > 0 ? (
                    <>
                        {menuLinks && <StickyMenu links={menuLinks} activeLink={activeCourse}></StickyMenu>}
                        <section id="layout_content" className={styles.layout_content}>
                            {courses
                                .sort((a, b) => (a.id < b.id ? 1 : -1))
                                .map(
                                    course =>
                                        course.hash && (
                                            <article className={styles.article} key={course.hash} id={course.hash}>
                                                {course.name && (
                                                    <div className={styles.article_heading}>
                                                        {course.hash && course.hash === ATSCourseHash.ASCENTENGINEERING && (
                                                            <img
                                                                src={ascent_engineering_logo}
                                                                alt="Alerton Engineering & Commissioning Certification"
                                                            />
                                                        )}
                                                        {course.hash && course.hash === ATSCourseHash.ASCENTPROGRAMMING && (
                                                            <img
                                                                src={ascent_programming_logo}
                                                                alt="Alerton Programming Certification Training"
                                                            />
                                                        )}
                                                        {course.hash && course.hash === ATSCourseHash.COMPASS && (
                                                            <img src={compass_logo} alt="Compass Operator Training" />
                                                        )}
                                                        <h2 id={course.name}>{course.name}</h2>
                                                    </div>
                                                )}
                                                <div className={styles.article_body}>
                                                    {course.description.split("/n").map((text, index) => (
                                                        <p key={`paragraph-${index}`}>{text}</p>
                                                    ))}

                                                    {course.sylabus && (
                                                        <a
                                                            id={course.sylabus}
                                                            className={styles.article_body_download}
                                                            href={course.sylabus}
                                                            target="_blank"
                                                            download
                                                            rel="noreferrer">
                                                            <i className="icon-download"></i>
                                                            <span>Click here to download the syllabus</span>
                                                        </a>
                                                    )}
                                                    <p>
                                                        Check our{" "}
                                                        <NavLink to={"/schedule"} state={{ activeFilter: course.value }}>
                                                            {" "}
                                                            upcoming training
                                                        </NavLink>
                                                        .
                                                    </p>

                                                    <p key={`paragraph-footer-${course.hash}`}>
                                                        To sign up for this class, please contact your{" "}
                                                        <a
                                                            href="https://buildings.honeywell.com/us/en/brands/our-brands/alerton/locate-a-dealer-page"
                                                            target="_blank"
                                                            rel="noreferrer">
                                                            local Alerton dealer
                                                        </a>
                                                        .
                                                    </p>
                                                    <p key={`paragraph-footer-faq-${course.hash}`}>
                                                        For all other questions, visit our{" "}
                                                        <NavLink to="/faq">Frequently Asked Questions </NavLink>
                                                        page.
                                                    </p>
                                                </div>
                                                <div className={styles.article_footer}>
                                                    <div className={styles.article_footer_set}>
                                                        <div
                                                            className={`${course.isLocationsOpen ? styles.active : ""} ${
                                                                styles.article_footer_location
                                                            } ${
                                                                course.seats > 0 && course.locations.filter(x => x.selected).length === 0
                                                                    ? styles.required
                                                                    : ""
                                                            }`}>
                                                            <button
                                                                className={styles.article_footer_location_top}
                                                                onClick={() => toggleVisibility(course.hash)}>
                                                                <span>{`${getLocationName(course.locations) ?? "Select Location"}`}</span>
                                                                <i className="icon-expand_more"></i>
                                                            </button>
                                                            {course.locations.length > 0 && (
                                                                <>
                                                                    <ul className={styles.article_footer_location_list}>
                                                                        {course.locations.map(location => (
                                                                            <li
                                                                                key={`location-${location.value}`}
                                                                                data-value={location.value}
                                                                                onClick={() => handleSelection(course.hash, location.value)}>
                                                                                {location.label}
                                                                            </li>
                                                                        ))}
                                                                    </ul>

                                                                    <span className={styles.tooltip}>Please select a location</span>
                                                                </>
                                                            )}
                                                        </div>

                                                        <div className={styles.article_footer_seats}>
                                                            <p>Number of seats</p>

                                                            <div className={styles.article_footer_seats_buttons}>
                                                                <button onClick={() => setSeats(course.hash, false)}>
                                                                    <i className="icon-remove"></i>
                                                                </button>
                                                                <input type="text" value={course.seats} readOnly />
                                                                <button onClick={() => setSeats(course.hash, true)}>
                                                                    <i className="icon-add"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className={styles.article_footer_goto}>
                                                        <NavLink
                                                            key={`checkout-${course.hash}`}
                                                            className={`${styles.article_footer_goto_btn} ${
                                                                !hasCheckoutInfo(course.hash) ? styles.disabled : ""
                                                            }`}
                                                            to={`/checkout`}
                                                            state={{ courseInfo: getCheckoutInfo(course.hash) }}
                                                            onClick={e => {
                                                                if (!hasCheckoutInfo(course.hash)) {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                }
                                                            }}>
                                                            <i className="icon-shopping_cart"></i>
                                                            <span>Go to checkout</span>
                                                            <i className={`icon-straight ${styles.arrow}`}></i>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            </article>
                                        )
                                )}
                        </section>
                        <img className={styles.layout_detail_right} src={detail_bg_right} alt="" />
                        <img className={styles.layout_detail_footer} src={detail_bg_footer} alt="" />
                    </>
                ) : (
                    <div className={styles.spinnerWrapper}>
                        <ATSSpinner title="Loading..." />
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default Courses;
