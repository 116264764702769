import { useEffect, useState } from "react";
import ATSCardInput from "../../../../components/form/ATSCardInput";
import ATSCvvInput from "../../../../components/form/ATSCvvInput";
import ATSExpirationInput from "../../../../components/form/ATSExpirationInput";
import ATSSelect, { SelectOption } from "../../../../components/form/ATSSelect";
import ATSTextInput from "../../../../components/form/ATSTextInput";
import ATSZipCodeInput from "../../../../components/form/ATSZipCodeInput";
import { copyJSON } from "../../../../helpers";
import CountriesService from "../../../../services/entitiesServices/CountriesService";


interface creditCardProps {
    state: any;
    errors?: any;
    handleInputChange: (name: string, value: string | number, _indexed?: string, _index?: number) => void;
    handleErrorsChange?: (name: string, value: string[], _indexed?: string, _index?: number) => void;
}
function CreditCard(props: creditCardProps) {
    const { handleInputChange, state, errors, handleErrorsChange } = props;
    const { paymentData: creditCard } = state;
    const [countriesList, setCountriesList] = useState<SelectOption[]>();

    const manageCardData = (name, value) => {
        let mutateState = copyJSON(state.paymentData);
        mutateState = {
            ...mutateState,
            [name]: value,
        };
        handleInputChange("paymentData", mutateState);
    };

    const manageErrorCard = (name, value) => {
        if (!handleErrorsChange) return;
        let mutateState = errors?.creditCard ? copyJSON(errors.creditCard) : {};
        mutateState = {
            ...mutateState,
            [name]: value,
        };
        handleErrorsChange("creditCard", mutateState);
    };

    const manageExpirationDate = (name, exp) => {
        const splitDate = exp.split("/");
        let mutateState = copyJSON(state.paymentData);
        mutateState = {
            ...mutateState,
            expirationMonth: splitDate[0] ? Number(splitDate[0]) : null,
            expirationYear: splitDate[1] ? Number(splitDate[1]) : null,
        };

        handleInputChange("paymentData", mutateState);
    };

    useEffect(() => {
        getCountriesList();
    }, []);


    async function getCountriesList() {
        const res = await CountriesService.getCountries();

        if (res && res.success) {
            if (res.response.items.length > 1) {
                setCountriesList(res.response.items.map(country => ({ value: country.code, label: country.name })));
            } else {
                setCountriesList(res.response.items.map(country => ({ value: country.code, label: country.name, default: true })));
            }
        }
    }


    return (
        <fieldset className="fieldset">
            <ATSCardInput
                name={"cardNumber"}
                label={"Card Number"}
                handleChange={manageCardData}
                errors={errors && errors.creditCard && errors.creditCard.cardNumber ? errors.creditCard.cardNumber : []}
                setErrorMessage={manageErrorCard}
            />
            <div className="columns-1x1">
                <ATSCvvInput
                    name={"cvc"}
                    label={"CVV"}
                    handleChange={manageCardData}
                    cardNumber={creditCard.cardNumber}
                    errors={errors && errors.creditCard && errors.creditCard.cvc ? errors.creditCard.cvc : []}
                    setErrorMessage={manageErrorCard}
                />
                <ATSExpirationInput
                    name={"expirationDate"}
                    label={"Exp. Date"}
                    handleChange={manageExpirationDate}
                    errors={errors && errors.creditCard && errors.creditCard.expiration ? errors.creditCard.expiration : []}
                    setErrorMessage={manageErrorCard}
                />
            </div>
            <ATSTextInput
                name={"name"}
                label={`Cardholder's Name`}
                value={state.paymentData.name}
                handleChange={manageCardData}
                error={errors && errors.creditCard && errors.creditCard.name ? errors.creditCard.name : []}
                setErrorMessage={manageErrorCard}
                required
            />
            <div className="columns-2x1">
                <ATSSelect
                    options={countriesList ?? []}
                    name={"country"}
                    label={"Country"}
                    placeholder={"Select Country"}
                    handleChange={manageCardData}
                    error={errors && errors.creditCard && errors.creditCard.country ? errors.creditCard.country : []}
                    setErrorMessage={manageErrorCard}
                    required
                />
                <ATSZipCodeInput
                    name={"postalCode"}
                    label={"Zip Code"}
                    handleChange={manageCardData}
                    country={state.paymentData.country ?? state.paymentData.country}
                    value={state.paymentData.postalCode}
                    error={errors && errors.creditCard && errors.creditCard.postalCode ? errors.creditCard.postalCode : []}
                    setErrorMessage={manageErrorCard}
                    required
                />
            </div>
        </fieldset>
    );
}

export default CreditCard;
