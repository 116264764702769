import { DateTime } from "luxon";

export function copyJSON(json) {
    return JSON.parse(JSON.stringify(json));
}
export const currencyDollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

//Merge Hours and minutes that comes from the DB.
export function mergeTime(hour: number, min: number): string {
    return `${hour.toLocaleString().length === 1 ? "0" + hour : hour}:${min.toLocaleString().length === 1 ? "0" + min : min}`;
}

export function formatDate(date: string): string {
    // CHECK IF UTC IS CORRECT
    const formattedDate = DateTime.fromISO(date).setZone("UTC").toFormat("MM/dd/yyyy");
    return formattedDate;
}

export function formatDateAndTime(date: string): string {
    // CHECK IF UTC IS CORRECT
    const formattedDate = DateTime.fromISO(date).setZone("UTC").toFormat("MM/dd/yyyy - hh:mm a  (ZZZZ)");
    return formattedDate;
}

export function formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
}

//#region TimeConverter
/**
 * Time converter
 * @param time string; -> of 24hs format 24:00:00
 * @returns
 * String of 24 hours format in AM PM
 * The string given needs at least four digits e.g. 14:03
 */
export function timeConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}
//#endregion

//#region getNormalizedStartTime
/**
 * Normalize times with 2 digits and return it
 * @param startTime
 * @param startTimeMinute
 * @returns
 */
export function getStartTime(startTime, startTimeMinute) {
    let time: string;

    if (startTime.toString().length > 1) {
        time = `${startTime}:`;
    } else time = `0${startTime}:`;

    if (typeof time !== "undefined" || time !== "") {
        if (startTimeMinute.toString().length > 1) time = time + startTimeMinute;
        else time = time + `0${startTimeMinute}`;
    }

    return time;
}
//#endregion

//#region Object Validations
export interface IObjectValidationsProperties {
    key: string;
    type: string;
    required?: boolean;
}
interface IObjectValidationsReturn {
    hasRequiredInputs: boolean;
    passTypeValidations: boolean;
    inputs: Array<{ key: string; has: boolean; typeValid?: boolean }>;
}
/**
 * Validate properties of an object
 * @param o object;
 * @param properties array; Must contain key and type for validate
 * @returns object;
 *
 * First validate if the key exists, then validate type of the values
 * Some types in javascript refers an object, in this function only take the "Array" case. If you want to use another like "null"
 * add the validation.
 * The final object returns two keys with a boolean value thats say if all validations are passed or not and finally the object
 */
export function objectValidations(o: object, properties: Array<IObjectValidationsProperties>): IObjectValidationsReturn | undefined {
    let validations: Array<{ key: string; has: boolean; typeValid?: boolean }> = [];
    if (typeof o === "undefined") return;
    properties.forEach(property => {
        if (o.hasOwnProperty(property.key) && property.required) {
            const ifRequired = () => {
                if (property.type != "array") {
                    return typeof o[property.key] === property.type && o[property.key] !== "" && o[property.key] !== null;
                } else {
                    return Array.isArray(o[property.key]);
                }
            };
            validations.push({
                key: property.key,
                has: true,
                typeValid: ifRequired(),
            });
        } else if (o.hasOwnProperty(property.key)) {
            validations.push({
                key: property.key,
                has: true,
                typeValid: property.type != "array" ? typeof o[property.key] === property.type : Array.isArray(o[property.key]),
            });
        } else {
            validations.push({
                key: property.key,
                has: property.required ? false : true,
                typeValid: property.required ? false : true,
            });
        }
    });

    return {
        hasRequiredInputs: validations.every(x => x.has),
        passTypeValidations: validations.every(x => x.typeValid),
        inputs: validations,
    };
}

//#endregion
