import ApiBaseService, { IResponse } from "../apiServices/ApiBaseService";
import { IAddPaymentDealerRequest } from "../interfaces/serviceRequests/payment/IAddPaymentDealerRequest";
import { IAddPaymentDealerResponse } from "../interfaces/serviceResponses/payment/IAddPaymentDealerResponse";

class PaymentService extends ApiBaseService {
    public static async payDealer(request: IAddPaymentDealerRequest): Promise<IResponse<IAddPaymentDealerResponse>> {
        const response = await this.post<IAddPaymentDealerResponse>(`payment/payDealer`, request);
        return response;
    }
}

export default PaymentService;