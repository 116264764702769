import { useRef, useState } from "react";
import styles from "./ATSCustomSelect.module.scss";
import useEventListener from "../../../hooks/useEventListener";
import chevron from "../../../assets/media/chevron.svg";
interface IOption {
    value: string;
    label: string;
    default?: boolean;
}

interface ICustomSelect {
    options: IOption[];
    handleChange: (option: IOption) => void;
}

export default function ATSCustomSelect({ options, handleChange }: ICustomSelect) {
    const [expandedList, setExpandedList] = useState<boolean>(false);
    const defaultOption = options.find(option => option.default) ?? options[0];
    const [selectedOption, setSelectedOption] = useState<IOption>(defaultOption);
    const customSelect = useRef<HTMLDivElement>(null as any);

    function getDisplayedOption(option: IOption) {
        const newOption = option ?? defaultOption;
        setSelectedOption(newOption);
        handleChange(newOption);
        setExpandedList(false);
    }

    const handleClickOutside = (e: PointerEvent): void => {
        if (customSelect?.current && customSelect?.current.contains(e.target as Node)) return;
        setExpandedList(false);
    };

    useEventListener("pointerdown", handleClickOutside);
    return (
        <div className={styles.select_wrapper} ref={customSelect}>
            <button className={styles.article_footer_location_top} onClick={() => setExpandedList(!expandedList)}>
                <span>{selectedOption?.label}</span>
                <img src={chevron} className={expandedList ? styles.chevron_open : styles.chevron_closed} alt="chevron"></img>
            </button>
            {options.length > 0 && (
                <ul className={`${styles.select_list} ${expandedList ? styles.expanded : ""}`}>
                    {options.map(option => (
                        <li
                            key={`location-${option.value}`}
                            className={selectedOption?.value === option.value ? styles.active : ""}
                            data-value={option.value}
                            onClick={() => getDisplayedOption(option)}>
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
