import { useEffect, useState } from "react";
import { ATSSteps, IBaseStepProps } from "..";
import ATSSelect, { SelectOption } from "../../../components/form/ATSSelect";
import ATSTextInput from "../../../components/form/ATSTextInput";
import ATSButton, { ATSTypeButton } from "../../../components/Shared/ATSButton";
import { formatDate, getStartTime, timeConvert } from "../../../helpers";
import CheckoutService from "../../../services/entitiesServices/CheckoutService";
import CourseService from "../../../services/entitiesServices/CoursesService";
import DealerService from "../../../services/entitiesServices/DealerService";

interface ICourseStepProps extends IBaseStepProps {
    locationId: number;
    courseId: number;
    selectedInstance: any | null;
    setSelectedInstance: (param: any | null) => void;
    setDealer: (dealer) => void;
}

function CourseStep(props: ICourseStepProps) {
    const {
        handleInputChange,
        state,
        handleErrorsChange,
        errors,
        handleStep,
        selectedInstance,
        setSelectedInstance,
        locationId,
        courseId,
        setDealer,
    } = props;
    const [instanceCourseDropdowns, setInstanceCourseDropdown] = useState<SelectOption[]>();
    const [resetInstance, setResetInstance] = useState(false);

    //#region Cascade Dropdowns and values -> state and fetchs

    // Available
    const getAvailableTag = (total, occupied) => {
        if (total > occupied) return `(Available seats ${total - occupied})`;
        else return "At Capacity";
    };
    //

    const getInstanceDropdown = async (course, location) => {
        const response = await CheckoutService.getCourseInstances(course, location);
        if (response.success) {
            setResetInstance(false);
            response.response.instances.length > 0
                ? setInstanceCourseDropdown(
                    response.response.instances.map(instance => ({
                        value: instance.id,
                        label: `${formatDate(instance.date)} ${timeConvert(
                            getStartTime(instance.startTime, instance.startTimeMinute)
                        )} ${getAvailableTag(instance.seats, instance.occupiedSeats)}`,
                        default: state.courseInstanceId === instance.id,
                        disabled: !(state.students.length <= (instance.seats - instance.occupiedSeats))
                    }))
                )
                : setInstanceCourseDropdown(
                    response.response.instances.map(instance => ({
                        value: instance.id,
                        label: `${formatDate(instance.date)} ${timeConvert(
                            getStartTime(instance.startTime, instance.startTimeMinute)
                        )} ${getAvailableTag(instance.seats, instance.occupiedSeats)}`,
                        default: true,
                    }))
                );
        } else {
            //do something
        }
    };

    const getInstanceData = async courseId => {
        const response = await CourseService.getData(courseId);
        if (response && response.response) {
            setSelectedInstance(response.response);
        }
    };

    useEffect(() => {
        if (!locationId || !courseId) return;
        setSelectedInstance(null);
        getInstanceDropdown(courseId, locationId);
    }, [locationId, courseId]);

    useEffect(() => {
        if (!state.courseInstanceId) return;
        getInstanceData(state.courseInstanceId);
    }, [state.courseInstanceId]);

    //#endregion

    const nextStep = async () => {
        if (!state.token) return;
        //have 12 characters?
        if (state.token.length !== 12) {
            handleErrorsChange("token", ["Token number must have 12 characters"]);
            return;
        }

        //is valid?
        const response = await DealerService.getByToken(state.token);
        if (response.success) {
            setDealer(response.response);
            if (state.courseInstanceId && state.slots && state.token) handleStep(ATSSteps.NEXT);
        } else {
            handleErrorsChange("token", [response.message]);
            console.error(response);
        }
    };

    return (
        <fieldset className="fieldset">
            <h2 className="form_title">Course Information</h2>
            <ATSSelect
                name={"courseInstanceId"}
                options={instanceCourseDropdowns ?? []}
                placeholder={"Initial date and time"}
                label={"Course Schedule"}
                handleChange={handleInputChange}
                setErrorMessage={handleErrorsChange}
                error={errors?.courseInstanceId}
                disabled={instanceCourseDropdowns ? false : true}
                resetValues={resetInstance}
                required
            />
            <ATSTextInput
                value={state.token}
                label="Dealer Token"
                name="token"
                handleChange={handleInputChange}
                error={errors.token}
                setErrorMessage={handleErrorsChange}
                maxLengthProps={12}
            />

            <div className="btn-group">
                <ATSButton cta="Back" type={ATSTypeButton.OUTPOSITIVE} url={`/courses`} />
                <ATSButton
                    cta="Next"
                    type={ATSTypeButton.POSITIVE}
                    action={nextStep}
                    disabled={!state.courseInstanceId || !state.slots || !state.token}
                />
            </div>
        </fieldset>
    );
}

export default CourseStep;
