import { PropsWithChildren, ReactElement, useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import styles from './Layout.module.scss'
import { useLocation } from 'react-router-dom';

interface ILayoutProps {
    title: string,
}

function Layout(props: PropsWithChildren<ILayoutProps>): ReactElement {
    const { title, children } = props;

    const { pathname } = useLocation();

    useEffect(() => {
        const scrollArea = document.body;
        scrollArea.scrollTo(0, 0);
    }, [pathname]);


    return (
        <div className={styles.ats_container}>
            <Header {...{ title }} />

            <main className={styles.ats_main}>
                {children}
            </main>

            <Footer {...{ title }} />
        </div>
    )
}

export default Layout   