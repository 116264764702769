import { timeConvert, getStartTime } from "../../../../helpers";
import styles from "./ATSDateTile.module.scss";
export default function ATSDateTile({ date, hour, minutes, title }) {
    const newDate = new Date(date);
    const formattedDate = new Date(newDate.getTime() - newDate.getTimezoneOffset() * -60000);
    const [courseMonth, courseDay] = formattedDate.toLocaleDateString("en-us", { month: "short", day: "numeric" }).split(" ");

    return (
        <div className={styles.date_tile_wrapper}>
            <div className={styles.title_container}>
                <span className={styles.title}>{title}</span>
                <span className={styles.time}>{timeConvert(getStartTime(hour, minutes))}</span>
            </div>
            <span className={styles.dash_divider}></span>

            <div className={styles.day_container}>
                <span className={styles.month}>{courseMonth}</span>
                <span className={styles.day}>{courseDay}</span>
            </div>
        </div>
    );
}
