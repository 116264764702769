import color from "../utils/color";
import compass_logo from "../assets/media/compass_operator_logo.svg";
import engineering_logo from "../assets/media/ascent_engineering_logo.svg";
import programming_logo from "../assets/media/ascent_programming_logo.svg";

export const tileColorDict: Record<string, color> = {
    compass_operator_training: "compass",
    "alerton_engineering_&_commissioning_certification": "alertonEngineering",
    alerton_programming_certification_training: "alertonProgramming",
};
export const logoDict: Record<string, any> = {
    compass_operator_training: compass_logo,
    "alerton_engineering_&_commissioning_certification": engineering_logo,
    alerton_programming_certification_training: programming_logo,
};

export const anchorDict: Record<string, string> = {
    compass_operator_training: "compass",
    "alerton_engineering_&_commissioning_certification": "alerton-engineering",
    alerton_programming_certification_training: "alerton-programming",
};
