import styles from "./Header.module.scss";
import { NavLink } from "react-router-dom";
import logo from "../../../assets/media/AlertonTraining_Logo_White.svg";
import compass_logo from "../../../assets/media/compass_operator_logo.svg";
import engineering_logo from "../../../assets/media/ascent_engineering_logo.svg";
import programming_logo from "../../../assets/media/ascent_programming_logo.svg";
import { useState } from "react";

interface ILayoutHeaderProps {
    title: string;
}

function Header(props: ILayoutHeaderProps) {
    const { title } = props;
    const [openMenu, setOpenMenu] = useState<boolean>(false);

    const handleClick = e => {
        e.preventDefault();
    };

    return (
        <header className={`${styles.ats_header} ${openMenu ? styles.active : ""}`}>
            <div className={styles.ats_header_content}>
                <div className={styles.ats_header_identity}>
                    <NavLink to="/home">
                        <img src={logo} alt="Alerton Training Logo" />
                    </NavLink>
                    <span className="sr-only">{title}</span>
                </div>
                <div className={styles.ats_header_menu} onClick={() => setOpenMenu(!openMenu)}>
                    <i className="icon-menu"></i>
                    <i className="icon-close"></i>
                </div>
                <nav className={styles.ats_header_nav}>
                    <NavLink
                        id="showsubmenu"
                        key="#"
                        className={styles.showSubNav}
                        to={`#`}
                        onClick={e => {
                            handleClick(e);
                        }}>
                        <span>{`Training Courses`}</span>
                        <i className="icon-expand_more"></i>
                    </NavLink>

                    <div className={styles.ats_header_subnav}>
                        <NavLink key="courses" className={styles.ats_cta} to={`/courses#compass`}>
                            <div>
                                <img src={compass_logo} alt="Compass Operator Training" />
                            </div>
                            <span>{`Compass Operator Training`}</span>
                        </NavLink>
                        <NavLink key="courses2" className={styles.ats_cta} to={`/courses#alerton-engineering`}>
                            <div>
                                <img src={engineering_logo} alt="Alerton Engineering & Commissioning Certification" />
                            </div>
                            <span>{`Alerton Engineering & Commissioning Certification`}</span>
                        </NavLink>
                        <NavLink key="courses3" className={styles.ats_cta} to={`/courses#alerton-programming`}>
                            <div>
                                <img src={programming_logo} alt="Alerton Programming Certification Training" />
                            </div>
                            <span>{`Alerton Programming Certification Training`}</span>
                        </NavLink>
                    </div>

                    <NavLink key="schedule" className={styles.ats_cta} to={`/schedule`}>
                        <span>{`Schedule`}</span>
                    </NavLink>

                    <NavLink key="faq" className={styles.ats_cta} to={`/faq`}>
                        <span>{`FAQ`}</span>
                    </NavLink>

                    <NavLink key="contact" className={styles.ats_cta} to={`/contact`}>
                        <span>{`Contact`}</span>
                    </NavLink>
                </nav>
            </div>
        </header>
    );
}

export default Header;
