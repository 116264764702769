import { PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./StickMenu.module.scss";

export interface IMenuLink {
    label: string;
    route: string;
    hash?: string;
}
export interface IStickyMenuProps {
    links: IMenuLink[];
    activeLink?: string;
}

function StickyMenu(props: PropsWithChildren<IStickyMenuProps>): ReactElement {
    const stickyMenu = useRef<any>();
    const { links, activeLink } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            document.removeEventListener("click", handleOutsideClick, false);
        };
    }, []);

    useEffect(() => {
        if (!isOpen) {
            document.addEventListener("click", handleOutsideClick, false);
        } else {
            document.removeEventListener("click", handleOutsideClick, false);
        }
    }, [isOpen]);

    function handleClick() {
        setIsOpen(!isOpen);
    }

    function handleOutsideClick(e) {
        if (!stickyMenu.current.contains(e.target)) {
            setIsOpen(false);
        }
    }

    return (
        <aside ref={stickyMenu} className={styles.aside}>
            <div className={`${isOpen ? styles.active : ""} ${styles.aside_box}`}>
                <i className="icon-expand_less" onClick={handleClick}></i>
                {links?.length > 0 &&
                    links.map(link => (
                        <Link
                            className={`${activeLink === link.hash ? styles.active : ""}`}
                            key={`${link.route}-${link.hash}`}
                            to={{ pathname: link.route, hash: `#${link.hash}` }}>
                            {link.label}
                        </Link>
                    ))}
            </div>
        </aside>
    );
}

export default StickyMenu;
