import ApiService from "./ApiService";

export interface IResponse<T> {
    success: boolean;
    message: string;
    httpStatusCode: number;
    response: T;
}

class ApiBaseService {
    private static readonly isWindow: any = typeof window !== "undefined" ? window : null;
    private static readonly token: string = this.isWindow ? (localStorage.getItem("jwt-token") as string) : "";

    // ********************************************** //
    // ************ Unsecured  methods ************** //
    // ********************************************** //
    //#region unsecured
    // -- Unsecured get
    protected static async get<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiService.get(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error) {
            return { success: false, message: "Unexpected error", httpStatusCode: 0, response: null as any };
        }
    }

    // -- Unsecured post
    protected static async post<T>(url: string, params?: any, headers?: any): Promise<IResponse<T>> {
        try {
            let res = await ApiService.post(url, params, headers);
            let data = res.data;
            let response: IResponse<T> = data;

            return response;
        } catch (error) {
            return { success: false, message: "Unexpected error", httpStatusCode: 0, response: null as any };
        }
    }

    // ********************************************** //
    // ************ Unsecured  methods ************** //
    // ********************************************** //
    //#endregion
}
export default ApiBaseService;
